export const seatTypeObj = {
  0: "Not set",
  1: "Window",
  2: "Aisle",
  3: "Middle",
  4: "WindowRecline",
  5: "WindowWing",
  6: "WindowExitRow",
  7: "WindowReclineWing",
  8: "WindowReclineExitRow",
  9: "WindowWingExitRow",
  10: "AisleRecline",
  11: "AisleWing",
  12: "AisleExitRow",
  13: "AisleReclineWing",
  14: "AisleReclineExitRow",
  15: "AisleWingExitRow",
  16: "MiddleRecline",
  17: "MiddleWing",
  18: "MiddleExitRow",
  19: "MiddleReclineWing",
  20: "MiddleReclineExitRow",
  21: "MiddleWingExitRow",
  22: "WindowReclineWingExitRow",
  23: "AisleReclineWingExitRow",
  24: "MiddleReclineWingExitRow",
  25: "WindowBulkhead",
  26: "WindowQuiet",
  27: "WindowBulkheadQuiet",
  28: "MiddleBulkhead",
  29: "MiddleQuiet",
  30: "MiddleBulkheadQuiet",
  31: "AisleBulkhead",
  32: "AisleQuiet",
  33: "AisleBulkheadQuiet",
  34: "CentreAisle",
  35: "CentreMiddle",
  36: "CentreAisleBulkHead",
  37: "CentreAisleQuiet",
  38: "CentreAisleBulkHeadQuiet",
  39: "CentreMiddleBulkHead",
  40: "CentreMiddleQuiet",
  41: "CentreMiddleBulkHeadQuiet",
  42: "WindowBulkHeadWing",
  43: "WindowBulkHeadExitRow",
  44: "MiddleBulkHeadWing",
  45: "MiddleBulkHeadExitRow",
  46: "AisleBulkHeadWing",
  47: "AisleBulkHeadExitRow",
  48: "NoSeatAtThisLocation",
  49: "WindowAisle",
  50: "NoSeatRow",
  51: "NoSeatRowExit",
  52: "NoSeatRowWing",
  53: "NoSeatRowWingExit",
  54: "WindowAisleRecline",
  55: "WindowAisleWing",
  56: "WindowAisleExitRow",
  57: "WindowAisleReclineWing",
  58: "WindowAisleReclineExitRow",
  59: "WindowAisleWingExitRow",
  60: "WindowAisleBulkhead",
  61: "WindowAisleBulkheadWing",
};

export const seatTypeJson = [
  { "Seat Type": "Not set", Number: "0" },
  { "Seat Type": "Window", Number: "1" },
  { "Seat Type": "Aisle", Number: "2" },
  { "Seat Type": "Middle", Number: "3" },
  { "Seat Type": "WindowRecline", Number: "4" },
  { "Seat Type": "WindowWing", Number: "5" },
  { "Seat Type": "WindowExitRow", Number: "6" },
  { "Seat Type": "WindowReclineWing", Number: "7" },
  { "Seat Type": "WindowReclineExitRow", Number: "8" },
  { "Seat Type": "WindowWingExitRow", Number: "9" },
  { "Seat Type": "AisleRecline", Number: "10" },
  { "Seat Type": "AisleWing", Number: "11" },
  { "Seat Type": "AisleExitRow", Number: "12" },
  { "Seat Type": "AisleReclineWing", Number: "13" },
  { "Seat Type": "AisleReclineExitRow", Number: "14" },
  { "Seat Type": "AisleWingExitRow", Number: "15" },
  { "Seat Type": "MiddleRecline", Number: "16" },
  { "Seat Type": "MiddleWing", Number: "17" },
  { "Seat Type": "MiddleExitRow", Number: "18" },
  { "Seat Type": "MiddleReclineWing", Number: "19" },
  { "Seat Type": "MiddleReclineExitRow", Number: "20" },
  { "Seat Type": "MiddleWingExitRow", Number: "21" },
  { "Seat Type": "WindowReclineWingExitRow", Number: "22" },
  { "Seat Type": "AisleReclineWingExitRow", Number: "23" },
  { "Seat Type": "MiddleReclineWingExitRow", Number: "24" },
  { "Seat Type": "WindowBulkhead", Number: "25" },
  { "Seat Type": "WindowQuiet", Number: "26" },
  { "Seat Type": "WindowBulkheadQuiet", Number: "27" },
  { "Seat Type": "MiddleBulkhead", Number: "28" },
  { "Seat Type": "MiddleQuiet", Number: "29" },
  { "Seat Type": "MiddleBulkheadQuiet", Number: "30" },
  { "Seat Type": "AisleBulkhead", Number: "31" },
  { "Seat Type": "AisleQuiet", Number: "32" },
  { "Seat Type": "AisleBulkheadQuiet", Number: "33" },
  { "Seat Type": "CentreAisle", Number: "34" },
  { "Seat Type": "CentreMiddle", Number: "35" },
  { "Seat Type": "CentreAisleBulkHead", Number: "36" },
  { "Seat Type": "CentreAisleQuiet", Number: "37" },
  { "Seat Type": "CentreAisleBulkHeadQuiet", Number: "38" },
  { "Seat Type": "CentreMiddleBulkHead", Number: "39" },
  { "Seat Type": "CentreMiddleQuiet", Number: "40" },
  { "Seat Type": "CentreMiddleBulkHeadQuiet", Number: "41" },
  { "Seat Type": "WindowBulkHeadWing", Number: "42" },
  { "Seat Type": "WindowBulkHeadExitRow", Number: "43" },
  { "Seat Type": "MiddleBulkHeadWing", Number: "44" },
  { "Seat Type": "MiddleBulkHeadExitRow", Number: "45" },
  { "Seat Type": "AisleBulkHeadWing", Number: "46" },
  { "Seat Type": "AisleBulkHeadExitRow", Number: "47" },
  { "Seat Type": "NoSeatAtThisLocation", Number: "48" },
  { "Seat Type": "WindowAisle", Number: "49" },
  { "Seat Type": "NoSeatRow", Number: "50" },
  { "Seat Type": "NoSeatRowExit", Number: "51" },
  { "Seat Type": "NoSeatRowWing", Number: "52" },
  { "Seat Type": "NoSeatRowWingExit", Number: "53" },
  { "Seat Type": "WindowAisleRecline", Number: "54" },
  { "Seat Type": "WindowAisleWing", Number: "55" },
  { "Seat Type": "WindowAisleExitRow", Number: "56" },
  { "Seat Type": "WindowAisleReclineWing", Number: "57" },
  { "Seat Type": "WindowAisleReclineExitRow", Number: "58" },
  { "Seat Type": "WindowAisleWingExitRow", Number: "59" },
  { "Seat Type": "WindowAisleBulkhead", Number: "60" },
  { "Seat Type": "WindowAisleBulkheadWing", Number: "61" },
];
