import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faFileInvoice,
  faHouseChimney,
  faSearch,
  faUsers,
  faUser,
  faStarHalf,
  faStar,
  faIndianRupeeSign,
  faArrowUpRightFromSquare,
  faPlus,
  faPencil,
  faUpload,
  faUtensils,
  faCheckCircle,
  faBan,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import "./SideNav.css";

const SideNav = () => {
  var navigate = useNavigate();
  return (
    <div className="adminPage-sideNav-block">
      <div className="adminPage-sideNav-logo">Tripbizz</div>
      <div className="adminPage-sideNav-content">
        <div className="adminPage-sideNav-links">
          <span onClick={() => navigate("/admin")}>
            <FontAwesomeIcon
              icon={faHouseChimney}
              style={{ "--fa-secondary-opacity": "1" }}
              className="adminPage-sideNav-icon"
            />
            &nbsp;&nbsp;Dashboard
          </span>
          <span onClick={() => navigate("/users")}>
            <FontAwesomeIcon
              icon={faUsers}
              className="adminPage-sideNav-icon"
            />
            &nbsp;&nbsp;Users
          </span>
          <span onClick={() => navigate("/flightsetting")}>
            <FontAwesomeIcon icon={faGear} className="adminPage-sideNav-icon" />
            &nbsp;&nbsp;Uploads
          </span>
          {/* <span onClick={() => navigate('/hotelsetting')}>
                        <FontAwesomeIcon icon={faGear} className='adminPage-sideNav-icon' />
                        &nbsp;&nbsp;Hotel Setting
                    </span> */}
          <span onClick={() => navigate("/commissionsetting")}>
            <FontAwesomeIcon icon={faGear} className="adminPage-sideNav-icon" />
            &nbsp;&nbsp;Commission Setting
          </span>
          <span onClick={() => navigate("/report")}>
            <FontAwesomeIcon
              icon={faFileInvoice}
              className="adminPage-sideNav-icon"
            />
            &nbsp;&nbsp;Report
          </span>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
