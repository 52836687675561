import React, { useContext, useEffect, useState } from "react";
import SideNav from "../SideNav/SideNav";
import { useNavigate, useParams } from "react-router-dom";
import MyContext from "../../Context";
import "./UserProfile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faIndianRupeeSign,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import LoadingProg from "../../Loading/LoadingProg";
import Popup from "../../Popup";
import { Pagination } from "@mui/material";

const UserProfile = () => {
  const [mounted, setMounted] = useState(true);
  const [openBalance, setOpenBalance] = useState(false);
  const [details, setDetails] = useState(null);
  const [price, setPrice] = useState(0);
  const [openTransactions, setOpenTransactions] = useState(false);
  const [newTripPopup, setNewTripPopup] = useState(false);
  const [tripname, settripname] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { actions, adminUserLoading, adminUserData } = useContext(MyContext);
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();

  const TRIPS_PER_PAGE = 10;

  const getData = async (pageNum = 1) => {
    const result = await actions.getUserByIdAdmin(id, pageNum);
    setTotalPages(Math.ceil(result.totalTrips / TRIPS_PER_PAGE));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getData(value);
  };

  const handleSubmitTrip = async () => {
    if (tripname === "") return;
    var newtripId = await actions.createTripFromAdmin(tripname, id);
    navigate(`trips/${newtripId}`);
  };

  useEffect(() => {
    if (mounted) {
      getData();
    }
    return () => {
      setMounted(false);
    };
  }, []);
  if (adminUserLoading) {
    return (
      <LoadingProg
        condition={adminUserLoading}
        loadingText="Getting User Details..."
        progEnd={adminUserLoading}
        progTime={4}
      />
      // <>Loading..</>
    );
  }
  console.log(adminUserData);

  return (
    <>
      <Popup
        condition={openBalance}
        close={() => {
          setOpenBalance(false);
        }}
      >
        <div className="update-balance">
          <label>
            Add Money
            <input
              name="balance"
              type="number"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
            />
          </label>
          <label>
            Details
            <input
              name="balance"
              type="text"
              value={details}
              onChange={(e) => {
                setDetails(e.target.value);
              }}
            />
          </label>
          <button
            onClick={async () => {
              setOpenBalance(0);
              setOpenBalance(false);
              await actions.updateAdminBalance(id, price, details);
            }}
          >
            Add Money
          </button>
        </div>
      </Popup>
      <Popup
        condition={openTransactions}
        close={() => {
          setOpenTransactions(false);
        }}
      >
        <div className="transactions-block">
          <div className="transactions-header">Transactions</div>
          <div className="transactions-content">
            <div className="transactions-content-header">
              <span>Date/Time</span>
              <span>Application</span>
              <span>Type</span>
              <span>Amount</span>
              <span>Balance</span>
            </div>
            {adminUserData?.transactions
              ?.sort((a, b) => {
                var aDate = new Date(a.Date);
                var bDate = new Date(b.Date);
                return bDate - aDate;
              })
              ?.map((payment, index) => {
                return (
                  <div
                    className={`transactions-content-body ${
                      index % 2 === 0 ? "even" : "odd"
                    }`}
                  >
                    <span>
                      {new Date(payment.Date).toLocaleString("en-In")}
                    </span>
                    <span>{payment.application}</span>
                    <span>{payment.type}</span>
                    <span>
                      {payment.type === "Debit" ? (
                        <FontAwesomeIcon icon={faMinus} className="icon" />
                      ) : (
                        <FontAwesomeIcon icon={faPlus} className="icon" />
                      )}
                      &nbsp;
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        className="icon"
                      />
                      {Math.ceil(payment.amount)}
                    </span>
                    <span>
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        className="icon"
                      />
                      {Math.ceil(payment.balance)}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </Popup>
      <Popup condition={newTripPopup} close={() => setNewTripPopup(false)}>
        <h1 className="font-bold text-[20px]">Enter Trip name</h1>

        <textarea
          required={true}
          cols={40}
          rows={3}
          className="border border-solid border-black rounded-md py-1 px-1 placeholder:text-[17px]"
          placeholder="Enter trip name"
          value={tripname}
          onChange={(e) => settripname(e.target.value)}
        />
        <br />
        <button
          className="bg-black text-white rounded-lg  px-3 py-1 block m-auto"
          onClick={handleSubmitTrip}
        >
          Create
        </button>
      </Popup>
      <div className="userProfile-admin-container">
        <SideNav />
        <div className="userProfile-admin-block">
          <div className="userProfile-header-back">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="userProfile-header-back-icon"
              onClick={async () => {
                navigate(`/users`);
              }}
            />
          </div>
          <div className="userProfile-admin-balance">
            <div className="userProfile-admin-balance-details">
              <span>
                {adminUserData.firstName}&nbsp;{adminUserData.lastName}
              </span>
              <span>{adminUserData.email}</span>
              <span>{adminUserData.mobileNumber}</span>
              <span>Account Type: {adminUserData.accountType}</span>
              <span>Approval Type: {adminUserData.approvalType}</span>
            </div>
            <div className="userProfile-admin-balance-content">
              <div className="balance-header">
                Wallet Balance:{" "}
                <span>
                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                  &nbsp;{Math.ceil(adminUserData.balance).toLocaleString()}
                </span>
              </div>
              <div className="balance-button">
                <button
                  onClick={() => {
                    setOpenBalance(true);
                  }}
                >
                  Add Money
                </button>
                <button
                  onClick={() => {
                    setOpenTransactions(true);
                  }}
                >
                  Show Transactions
                </button>
              </div>
            </div>
          </div>
          {/* <div className='d-flex gap-10 px-[10pt] py-[20pt] m-[20pt] rounded-[0.8rem] userProfile-admin-type '>
                        <div className='d-flex flex-col w-1/2'>
                            <span className='font-bold text-[15pt]'>Account Type </span>
                            <select class="custom-select custom-select-lg mb-3 bg-[rgb(246,246,246)] p-3 rounded-[0.8rem]" onChange={(e) => actions.changeAccountType("payment", e.target.value, adminUserData.userid)}>
                                <option selected={adminUserData.accountType === "PrePaid"}>PrePaid</option>
                                <option selected={adminUserData.accountType === "PostPaid"}>PostPaid</option>
                            </select>
                        </div>
                        <div className='d-flex flex-col w-1/2'>
                            <span className='font-bold text-[15pt]'>Approval Type </span>
                            <select class="custom-select custom-select-lg mb-3 bg-[rgb(246,246,246)] p-3 rounded-[0.8rem]" onChange={(e) => actions.changeAccountType("approval", e.target.value, adminUserData.userid)}>
                                <option selected={adminUserData.approvalType === "Mandatory"}>Mandatory</option>
                                <option selected={adminUserData.approvalType === "Non Mandatory"}>Non Mandatory</option>
                            </select>
                        </div>
                    </div> */}
          <div className="userProfile-admin-trips">
            <div className="userProfile-admin-trips-header">
              <div>Trips</div>
              <div>
                <button
                  onClick={async () => {
                    // actions.setAdminUserId(id);
                    // navigate("/home/flights");
                    // await actions.getUserTripsById(id);
                    setNewTripPopup(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp; Create Trip
                </button>
              </div>
            </div>
            <div className="userProfile-admin-trips-content">
              <div className="userProfile-admin-trips-header">
                <span>Name</span>
                <span>Created At</span>
                <span>Flights</span>
                <span>Hotels</span>
                <span>Bus</span>
                <span>Cabs</span>
                <span>Other</span>
              </div>
              {adminUserData?.trips.length > 0 ? (
                <>
                  {adminUserData?.trips
                    ?.sort((a, b) => {
                      var aDate = new Date(a?.data?.date?.seconds * 1000);
                      var bDate = new Date(b?.data?.date?.seconds * 1000);

                      return bDate - aDate;
                    })
                    ?.map((trip) => {
                      var createdAt = new Date(trip?.data?.date?.seconds * 1000)
                        .toString()
                        .slice(4, 16);
                      return (
                        <div
                          className="userProfile-admin-trips-items"
                          onClick={async () => {
                            navigate(`trips/${trip.id}`);
                          }}
                        >
                          <span>{trip?.data?.name}</span>
                          <span>{createdAt}</span>
                          <span>{trip?.data?.flights?.length}</span>
                          <span>{trip?.data?.hotels?.length}</span>
                          <span>{trip?.data?.bus?.length}</span>
                          <span>{trip?.data?.cabs?.length}</span>
                          <span>{trip?.data?.otherBookings?.length}</span>
                        </div>
                      );
                    })}
                  <div className="flex justify-center mt-[20px]">
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                      size="large"
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
