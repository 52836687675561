import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBan,
  faChevronDown,
  faChevronUp,
  faIndianRupeeSign,
  faSuitcaseRolling,
  faPlaneDeparture,
  faDownload,
  faUpload,
  faCutlery,
  faCalendarXmark,
  faChair,
  faTrash,
  faCircleInfo,
  faPencilAlt,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import firebase from "firebase/compat/app";
import { collection, getDocs } from "firebase/firestore";
import Popup from "../../Popup";
import "./Flight.css";

import { ScaleLoader } from "react-spinners";
import TravDetails from "../../Trips/TripDetails/TravellerDetails";
import FlightPriceCard from "../../Trips/TripDetails/FlightPriceCard";
import LoadingProg from "../../Loading/LoadingProg";
import FlightCard from "../../Trips/TripDetails/FlightCard";
import { MdDelete } from "react-icons/md";
import { Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { LuAlarmClock } from "react-icons/lu";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { getAuth } from "firebase/auth";
import InvoicePdf1 from "../../InvoicePdf1";
import { useParams } from "react-router-dom";
const ruleType = {
  Cancellation: "Cancellation",
  Reissue: "Date change",
};

function Flight(props) {
  const AdminId = getAuth().currentUser.uid;

  const { control, handleSubmit, setValue, reset } = useForm();
  const [baggage, setBaggage] = useState(false);
  const [baggageDtls, setBaggageDtls] = useState({});
  const [tripsBaggage, setTripsBaggage] = useState(false);
  const [tripsCancellation, setTripsCancellation] = useState(false);
  const [tripsMeals, setTripsMeals] = useState(false);
  const [tripsSeat, setTripsSeat] = useState(false);
  const [cancellation, setCancellation] = useState(false);
  const [cancelDtls, setCancelDtls] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stopDtls, setStopDtls] = useState([]);
  const [showStopDtls, setShowStopDtls] = useState(false);
  const [airlinelogos, setAirlinelogos] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState(null);
  const [note, setNote] = useState("");
  const [ticketCost, setTicketCost] = useState("");
  const [mounted, setMounted] = useState(true);
  const [open, setOpen] = useState(false);
  const [openTravellers, setOpenTravellers] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [deleteType, setDeleteType] = useState(false);
  const [openFlightPrice, setOpenFlightPrice] = useState(false);
  const [openFareRules, setOpenFareRules] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAdminPrice, setOpenAdminPrice] = useState(false);
  const [openPriceReCheck, setOpenPriceReCheck] = useState(false);
  const [reCheckLoading, setReCheckLoading] = useState(false);
  const [reCheckData, setReCheckData] = useState(null);
  const [reCheckBaggage, setReCheckBaggage] = useState(0);
  const [reCheckMeals, setReCheckMeals] = useState(0);
  const [reCheckSeats, setReCheckSeats] = useState(0);
  const [openAdminFareRules, setOpenAdminFareRules] = useState(false);
  const [segmentIndex, setSegmentIndex] = useState(0);
  const [recheckSeatsAvailable, setRecheckSeatsAvailable] = useState(false);
  const [addTravellers, setAddTravellers] = useState(false);
  const [newtravellerDetails, setNewTravellerDetails] = useState();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [newFlightId, setNewFlightid] = useState();
  const [alltimeStamp, setAllTimeStamp] = useState(false);
  const [timeStampDate, setTimeStampData] = useState();
  const [invoiceData, setInvoiceData] = useState([]);
  var statuses = [
    { status: "Submitted", color: "#ffa500" },
    // { status: "Need clarification", color: "#FFC107" },
    // { status: "Price Revision", color: "#2196F3" },
    { status: "Booked", color: "#4CAF50" },
    { status: "Cancelled", color: "#FF0000" },
    // { status: "Submitted,Payment Pending", color: "#ffa500" },
    // { status: "Booked,Payment Pending", color: "#4AF50" },
    { status: "Not Submitted", color: "#808080" },
  ];
  var reqStatuses = [
    { status: "Approved", color: "#008000" },
    { status: "Pending", color: "#ffa500" },
    { status: "Not Requested", color: "#808080" },
    { status: "Skipped", color: "#808080" },
  ];

  const {
    actions,
    bookingFlight,
    flightResJType,
    flightResList,
    domesticFlight,
    downloadURL,
    userAccountDetails,
    tripData,
    adminTripDetails,
    minimumServiceCharge,
    recheckError,
    recheckLoad,
  } = useContext(MyContext);
  console.log(minimumServiceCharge);
  var {
    adminTripdata,
    userPage,
    flightGrp,
    index,
    bookingPage,
    tripsPage,
    adminPage,
    flightBooking,
    flightStatus,
    flightData,
    downloadUrl,
    adminBooking,
    removeFilters,
    travellerDetails,
    timeStamp,
    flightId,
    flightReq,
    adminTripid,
    tripId,
    updatedAt,
    flight,
    isInternational,
    id,
    totalFlight,
    charge,
    gst,
    user,
    userId,
  } = props;
  console.log(adminBooking);
  var reqColor = reqStatuses.filter((status) => {
    return status?.status === flightStatus?.requestStatus;
  });
  const toggle = (e) => {
    setIsOpen((prev) => !prev);
    actions.toggle(e, `#flightIndex${index}`);
  };
  console.log(bookingFlight);
  var flightArr = flightGrp.map((flight, f) => {
    return { ...actions.modifyFlightObject(flight) };
  });
  console.log(totalFlight);
  const getData = async () => {
    const db = firebase.firestore();
    const AccountsCollectionRef = collection(db, "airlinelogos");
    const docSnap = await getDocs(AccountsCollectionRef);
    var updatedAirlinelogos = [];
    docSnap.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      updatedAirlinelogos.push({ id: doc.id, url: doc.data().url });
      setAirlinelogos({ ...setAirlinelogos, [doc.id]: doc.data().url });
    });
    setAirlinelogos(updatedAirlinelogos);
  };
  const onSubmit = (data) => {
    console.log(flight.id);
    const newData = { [flight.id]: data };
    console.log(newData);
    // setNewTravellerDetails({
    //   ...newtravellerDetails,
    //   [id]: data,
    // });
    // console.log(newtravellerDetails);
    actions.updateTravDetails(newData, tripId);
    setAddTravellers(false);
    // console.log(data); // Handle form submission here
  };
  var originalDate = flightStatus?.updatedAt
    ? new Date(flightStatus.updatedAt?.seconds * 1000)
    : new Date(timeStamp);
  var threeHoursAfter = new Date(originalDate.getTime() + 3 * 60 * 60 * 1000);
  var currentTime = new Date();
  var isTimeReCheck =
    flightStatus?.status === "Not Submitted"
      ? currentTime > threeHoursAfter
      : false;

  // var invoiceData=[]
  useEffect(() => {
    const fetch = async () => {
      const data = await actions.getInvoiceDetails(userId, tripId);
      if (data.length > 0) {
        setInvoiceData(data);
      }
    };
    fetch();
  }, []);
  var isThere = null;
  if (invoiceData.length > 0) {
    console.log(totalFlight);
    isThere = invoiceData.find((item) => item.cardId === totalFlight?.[0]?.id);
    console.log(isThere);
  }
  useEffect(() => {
    if (mounted) {
      getData();
    }
    return () => [setMounted(false)];
  }, []);
  useEffect(() => {
    if (newtravellerDetails) {
      setIsFormDisabled(true);
      if (newtravellerDetails.children) {
        newtravellerDetails.children.forEach((child, index) => {
          setValue(`children[${index}].gender`, child.gender);
          setValue(`children[${index}].firstName`, child.firstName);
          setValue(`children[${index}].lastName`, child.lastName);
          // Set other values similarly
          if (isInternational) {
            setValue(`children[${index}].passportNumber`, child.passportNumber);
            setValue(
              `children[${index}].passportIssueCountry`,
              child.passportIssueCountry
            );
            setValue(
              `children[${index}].passportIssueDate`,
              child.passportIssueDate
            );
            setValue(
              `children[${index}].passportExpiryDate`,
              child.passportExpiryDate
            );
            setValue(`children[${index}].birthDate`, child.birthDate);
          }
        });
      }
      if (newtravellerDetails.adults) {
        newtravellerDetails.adults.forEach((adults, index) => {
          setValue(`adults[${index}].gender`, adults.gender);
          setValue(`adults[${index}].firstName`, adults.firstName);
          setValue(`adults[${index}].lastName`, adults.lastName);
          if (index === 0) {
            setValue(`adults[${index}].email`, adults.email);
            setValue(`adults[${index}].mobileNumber`, adults.mobileNumber);
          }
          // Set other values similarly
          if (isInternational) {
            setValue(`adults[${index}].passportNumber`, adults.passportNumber);
            setValue(
              `adults[${index}].passportIssueCountry`,
              adults.passportIssueCountry
            );
            setValue(
              `adults[${index}].passportIssueDate`,
              adults.passportIssueDate
            );
            setValue(
              `adults[${index}].passportExpiryDate`,
              adults.passportExpiryDate
            );
            setValue(`adults[${index}].birthDate`, adults.birthDate);
          }
        });
      }
      if (newtravellerDetails.infants) {
        newtravellerDetails.infants.forEach((infants, index) => {
          setValue(`infants[${index}].gender`, infants.gender);
          setValue(`infants[${index}].firstName`, infants.firstName);
          setValue(`infants[${index}].lastName`, infants.lastName);
          // Set other values similarly
          if (isInternational) {
            setValue(
              `infants[${index}].passportNumber`,
              infants.passportNumber
            );
            setValue(
              `infants[${index}].passportIssueCountry`,
              infants.passportIssueCountry
            );
            setValue(
              `infants[${index}].passportIssueDate`,
              infants.passportIssueDate
            );
            setValue(
              `infants[${index}].passportExpiryDate`,
              infants.passportExpiryDate
            );
            setValue(`infants[${index}].birthDate`, infants.birthDate);
          }
        });
      }
    }
  }, [newtravellerDetails, setValue]);

  var downloadDoc = async () => {
    var downloadName = downloadUrl.slice(164, downloadUrl.indexOf("?"));
    const response = await fetch(downloadUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = downloadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  var downloadName = flightData?.downloadURL?.slice(
    164,
    flightData?.downloadURL.indexOf("?")
  );

  var handleAdminDownload = async () => {
    var downloadName = flightData?.downloadURL?.slice(
      164,
      flightData?.downloadURL.indexOf("?")
    );
    const response = await fetch(flightData?.downloadURL);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = downloadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  var handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  var handleFileSubmit = async () => {
    var doc = await actions.addBookingDocuments(
      file,
      adminBooking.data.userDetails.userid,
      adminBooking.data.tripId,
      hotelId,
      adminBooking.id,
      "flight"
    );
    var data = [flightBooking].map((book, b) => {
      return (
        book.flightNew.segments[0].originAirportCode +
        "to" +
        book.flightNew.segments[0].destAirportCode
      );
    });
    var bookingFlight = adminBooking?.flights?.filter((flight) => {
      return flight.id === hotelId;
    });
    if (status === "Booked" || status === "Booked,Payment Pending") {
      await actions.sendBookingStatusEmail({
        id: adminBooking?.data?.userDetails?.userid,
        name: adminBooking?.data?.userDetails?.firstName,
        email: adminBooking?.data?.userDetails?.email,
        fileUrl: doc,
        tripName: adminBooking?.data?.tripName,
        typeName: data,
        type: "Flight",
        booking: bookingFlight,
        travellerDetails: adminBooking.data.travellerDetails,
      });
    }
    setOpenUpload(false);
  };

  var handleClick = async (hotelId, statuse) => {
    await actions.editTripStatus(
      adminBooking.data.userDetails.userid,
      adminBooking.data.tripId,
      adminBooking.id,
      statuse,
      hotelId,
      "flight"
    );

    var data = [flightBooking].map((book, b) => {
      return (
        book.flightNew.segments?.[0]?.originAirportCode +
        "to" +
        book.flightNew.segments?.[0]?.destAirportCode
      );
    });
    if (!file) {
      var bookingFlight = adminBooking?.flights?.filter((flight) => {
        return flight.id === hotelId;
      });
      console.log(bookingFlight, adminBooking.data.travellerDetails);
      if (status === "Booked" || status === "Booked,Payment Pending") {
        await actions.sendBookingStatusEmail({
          id: adminBooking?.data?.userDetails?.userid,
          name: adminBooking?.data?.userDetails?.firstName,
          email: adminBooking?.data?.userDetails?.email,
          fileUrl: null,
          tripName: adminBooking?.data?.tripName,
          typeName: data,
          type: "Flight",
          booking: bookingFlight,
          travellerDetails: adminBooking.data.travellerDetails,
        });
      }
    }
  };

  var addTicketCost = async () => {
    await actions.addTicketCostAdmin(
      ticketCost,
      adminBooking.data.userDetails.userid,
      adminBooking.data.tripId,
      hotelId,
      adminBooking.id,
      "flight"
    );
    setOpenUpload(false);
  };

  var addNote = async () => {
    await actions.addNoteAdmin(
      note,
      adminBooking.data.userDetails.userid,
      adminBooking.data.tripId,
      hotelId,
      adminBooking.id,
      "flight"
    );
    setOpenUpload(false);
  };

  var handleDelete = async () => {
    await actions.deleteTripItem(tripId, deleteId, deleteType);
    setOpenDelete(false);
  };

  var color = statuses.filter((status) => {
    return status?.status === flightStatus?.status;
  });
  var adColor = statuses.filter((status) => {
    return status?.status === flightData?.status;
  });

  var setBaggageOpen = (e) => {
    e.stopPropagation();
    setTripsBaggage(true);
  };
  var setCancellationOpen = (e) => {
    e.stopPropagation();
    setTripsCancellation(true);
  };
  var setMealsOpen = (e) => {
    e.stopPropagation();
    setTripsMeals(true);
  };
  var setSeatsOpen = (e) => {
    e.stopPropagation();
    setTripsSeat(true);
  };
  var setFareRules = (e) => {
    e.stopPropagation();
    setOpenFareRules(true);
  };
  var getUpdatedFares = (ssrData) => {
    var baggagePrice = 0;
    var mealPrice = 0;
    var seatPrice = 0;

    if (ssrData?.Baggage) {
      flightBooking?.selectedBaggage?.forEach((selectedBaggage, s) => {
        selectedBaggage.forEach((selected) => {
          const matchingBag = ssrData.Baggage[s].find(
            (bag) =>
              bag.Weight === selected.baggage && bag.Price === selected.price
          );
          if (matchingBag) {
            baggagePrice += matchingBag.Price;
          }
        });
      });
    }
    if (ssrData?.MealDynamic) {
      flightBooking?.selectedMeals?.forEach((selectedMeals, s) => {
        selectedMeals.forEach((selected) => {
          const matchingMeal = ssrData.MealDynamic[s].find(
            (meal) =>
              meal.AirlineDescription === selected.mealDesc &&
              meal.Price === selected.price
          );
          if (matchingMeal) {
            mealPrice += matchingMeal.Price;
          }
        });
      });
    }
    if (ssrData?.SeatDynamic) {
      var x = 0;
      flightBooking.seats.forEach((seat, s) => {
        seat.forEach((t, i) => {
          Object.keys(t).forEach((sa) => {
            var seatDetails = t[sa];
            var matchingData = ssrData?.SeatDynamic[s]?.SegmentSeat[
              i
            ]?.RowSeats[Number(seatDetails.RowNo)]?.Seats?.find(
              (X) =>
                X?.RowNo === seatDetails?.RowNo &&
                X?.SeatNo === seatDetails?.SeatNo
            );
            x += matchingData?.Price;
          });
        });
      });
    }
    console.log(seatPrice, x);
    setReCheckSeats(x);
    setReCheckBaggage(baggagePrice);
    setReCheckMeals(mealPrice);
  };
  const areSeatsAvailable = (seatData, selectedSeats) => {
    console.log(selectedSeats);
    if (!selectedSeats || !seatData) {
      setRecheckSeatsAvailable(true);
      return;
    }
    console.log(seatData);
    const allSeats = [];
    for (let segment of seatData) {
      for (let rowSeats of segment.Seats) {
        allSeats.push(rowSeats);
      }
    }

    // Check each selected seat
    for (let key in selectedSeats) {
      console.log(key);
      const selectedSeat = selectedSeats[key];
      console.log(allSeats);
      const seat = allSeats.find((s) => s.Code === key);
      console.log(seat);
      if (!seat || seat.AvailablityType !== String(1)) {
        setRecheckSeatsAvailable(false);
        // return false; // Seat is not available
      }
    }

    setRecheckSeatsAvailable(true); // All selected seats are available
  };

  var fareData = tripsPage ? actions.getTotalFares([flightBooking]) : "";
  var mainfare = adminPage ? actions.getTotalFares([flightBooking]) : "";
  var fData = adminPage ? actions.objToArr(flightBooking) : null;
  var id =
    flightBooking?.seats?.[0]?.length > 0
      ? Object.keys(flightBooking?.seats?.[0]?.[0])
      : "";
  var adminId =
    fData?.seats?.[0].length > 0 ? Object.keys(fData?.seats?.[0]?.[0]) : "";
  const adminFlightTrav =
    adminBooking?.data?.travellerDetails[newFlightId]?.adults;
  const adminFlightChild =
    adminBooking?.data?.travellerDetails[newFlightId]?.children;
  const adminFlightInfant =
    adminBooking?.data?.travellerDetails[newFlightId]?.infants;
  const getFlightStatusStyle = (status) => {
    switch (status) {
      case "Booked":
        return { background: "honeydew" };
      case "Cancelled":
        return { background: "#ffe4e4" };

      default:
        return null;
    }
  };
  return (
    <>
      <Popup condition={alltimeStamp} close={() => setAllTimeStamp(false)}>
        {tripsPage && (
          <div>
            <p>
              Added Date :
              {totalFlight &&
                totalFlight?.[0]?.date &&
                format(
                  new Date(totalFlight?.[0]?.date?.seconds * 1000),
                  "MMMM d, h:mm a"
                )}
            </p>
            <p>
              Sent to Approval :{" "}
              {totalFlight && totalFlight?.[0]?.manager_request_time
                ? format(
                    new Date(
                      totalFlight?.[0]?.manager_request_time?.seconds * 1000
                    ),
                    "MMMM d, h:mm a"
                  )
                : "Not Requested for Approval"}{" "}
            </p>
            <p>
              Approved Date :{" "}
              {totalFlight && totalFlight?.[0]?.managerApprovedTime
                ? format(
                    new Date(
                      totalFlight?.[0]?.managerApprovedTime?.seconds * 1000
                    ),
                    "MMMM d, h:mm a"
                  )
                : "Not Approved"}
            </p>
            <p>
              Submitted Date :{" "}
              {totalFlight && totalFlight?.[0]?.submitted_date
                ? format(
                    new Date(totalFlight?.[0]?.submitted_date?.seconds * 1000),
                    "MMMM d, h:mm a"
                  )
                : "Not Submitted"}
            </p>
            <p>
              Booked Date :{" "}
              {totalFlight && totalFlight?.[0]?.booked_date
                ? format(
                    new Date(totalFlight?.[0]?.booked_date?.seconds * 1000),
                    "MMMM d, h:mm a"
                  )
                : "Not Booked"}
            </p>
          </div>
        )}
      </Popup>
      <Popup
        condition={showStopDtls}
        close={() => {
          setStopDtls([]);
          setShowStopDtls(false);
        }}
      >
        <div className="flightStopDtls-popup-block">
          {stopDtls &&
            stopDtls.map((stop, s) => {
              return (
                <div className="flightStopDtls-popup-card">
                  {stop.layoverDur ? (
                    <div className="flightStopDtls-popup-card-layover">
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="flightStopDtls-popup-card-layover-icon"
                      />
                      {`Layover for ${stop.layoverDur} in ${stop.arrCity}`}
                    </div>
                  ) : null}
                  <div className="flightStopDtls-popup-card-route">
                    <div className="flightResults-list-flightCard-arrDep">
                      <div className="flightResults-list-flightCard-dep">
                        <div className="flightResults-list-flightCard-depTime">
                          {stop.depTime}
                        </div>
                        <div className="flightResults-list-flightCard-depCity">
                          {stop.originCode}
                        </div>
                      </div>
                      <div className="flightResults-list-flightCard-duration">
                        <div className="flightResult-list-flightCard-duration-stopPts"></div>
                        <div className="flightResults-list-flightCard-duration-time">
                          {stop.flightDur}
                        </div>
                      </div>
                      <div className="flightResults-list-flightCard-arr-section">
                        <div className="flightResults-list-flightCard-dep flightResults-list-flightCard-arr">
                          <div className="flightResults-list-flightCard-depTime">
                            {stop.arrTime}
                          </div>
                          <div className="flightResults-list-flightCard-depCity">
                            {stop.destCode}
                          </div>
                        </div>
                        {/* {segment.arrAfterDays > 0 ? (
                          <div className="flightResults-list-flightCard-depTime-afterDays">
                            <div className="flightResults-list-flightCard-depTime-afterDays-num">{`+ ${segment.arrAfterDays}`}</div>
                            <div>{`${
                              segment.arrAfterDays > 1 ? "Days" : "Day"
                            }`}</div>
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Popup>
      <Popup
        condition={baggage}
        close={() => {
          setBaggage(false);
          setBaggageDtls({});
        }}
      >
        <div className="flightBaggage-popup-block">
          {`${
            baggageDtls.baggage
              ? `Check-In baggage: ${baggageDtls.baggage}`
              : ""
          }${baggageDtls.baggage && baggageDtls.cabinBaggage ? " | " : ""} ${
            baggageDtls.cabinBaggage
              ? `Cabin baggage: ${baggageDtls.cabinBaggage}`
              : ""
          }`}
        </div>
      </Popup>
      <Popup
        condition={cancellation}
        close={() => {
          setCancellation(false);
          setCancelDtls([]);
        }}
      >
        {cancelDtls.length === 0 ? (
          <>
            <ScaleLoader
              // css={override}
              sizeUnit={"px"}
              size={17}
              color={"#94D2BD"}
              loading={cancelDtls.length === 0}
            />
            &nbsp;&nbsp;Loading Cancellation Data
          </>
        ) : (
          <>
            {Array.isArray(cancelDtls) ? (
              <>
                <div className="flightCancel-popup-block">
                  <div className="flightCancel-popup-tabs">
                    <div className="flightCancel-popup-tab">Journey points</div>
                    <div className="flightCancel-popup-tab">Type</div>
                    <div className="flightCancel-popup-tab">Range</div>
                    <div className="flightCancel-popup-tab">Charge</div>
                  </div>
                  <div className="flightCancel-popup-dtls">
                    {cancelDtls.map((ruleBlock, ru) => {
                      return (
                        <div className="flightCancel-popup-rulesSection">
                          {ruleBlock.map((rule, r) => {
                            return (
                              <div className="flightCancel-popup-rules">
                                <div className="flightCancel-popup-rule">
                                  {rule.JourneyPoints}
                                </div>
                                <div className="flightCancel-popup-rule">
                                  {ruleType[rule.Type]}
                                </div>
                                <div className="flightCancel-popup-rule">
                                  {rule.To === null ||
                                  rule.From === null ||
                                  rule.Unit === null
                                    ? "-"
                                    : rule.To === ""
                                    ? `> ${rule.From} ${rule.Unit} from departure`
                                    : rule.From === "0"
                                    ? `0 - ${rule.To} ${rule.Unit} from departure`
                                    : ` ${rule.To} - ${rule.From} ${rule.Unit} from departure`}
                                </div>
                                <div className="flightCancel-popup-rule">
                                  {rule.Details}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <div style={{ height: "80vh", overflow: "scroll" }}>
                <div dangerouslySetInnerHTML={{ __html: cancelDtls }} />
              </div>
            )}
          </>
        )}
      </Popup>
      <Popup condition={openUpload} close={() => setOpenUpload(false)}>
        <div className="admin-upload">
          <FlightPriceCard
            flightBooking={flightBooking}
            fareData={mainfare}
            tripsPage={adminPage}
          />
          <div className="admin-upload-popup">
            <div>
              <span>Add ticket cost:</span>
              <input
                type="text"
                placeholder="Enter Ticket Cost"
                value={ticketCost}
                onChange={(e) => setTicketCost(e.target.value)}
              />
            </div>
            <div>
              <span>Add a Note:</span>
              <textarea
                type="text"
                placeholder="Add note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                rows={4}
              />
            </div>
            <div className="admin-upload-file">
              <div>
                <span>Upload the file:</span>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                />
              </div>
              {flightData?.downloadURL ? (
                <div className="flight-file">
                  <div onClick={handleAdminDownload}>
                    <FontAwesomeIcon icon={faDownload} onClick={downloadDoc} />`
                    {downloadName?.slice(0, 40)}...`
                  </div>
                </div>
              ) : null}
            </div>
            <div className="adminPage-main-statusSave">
              <div className="adminPage-main-status">
                <div className="adminPage-main-status-text">Status</div>
                <div className="adminPage-status-btn">
                  <select
                    onChange={async (e) => {
                      console.log(e.target.value);
                      await setStatus(e.target.value);
                    }}
                  >
                    <option>
                      {
                        statuses.filter(
                          (status1) => status1?.status === flightData?.status
                        )?.[0]?.status
                      }
                    </option>
                    {statuses
                      .filter(
                        (status1) => status1.status !== flightData?.status
                      )
                      .map((status1) => (
                        <option value={status1?.status}>
                          {status1?.status}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            {loading ? (
              <button className="spin">
                <div className="spinner"></div>
              </button>
            ) : (
              <>
                {flightData?.status !== "Booked" &&
                  flightData?.status !== "Cancelled" && (
                    <button
                      disabled={
                        flightData?.status === "Booked" ||
                        flightData?.status === "Cancelled"
                      }
                      onClick={async () => {
                        setLoading(true);
                        if (status === "Booked") {
                          actions.addInvoices(
                            adminTripDetails?.data?.userDetails?.userid,
                            adminTripDetails?.data?.tripId,
                            hotelId
                          );
                        }
                        if (status !== flightData?.status) {
                          await handleClick(flightData?.id, status);
                        }
                        if (file) {
                          await handleFileSubmit();
                        }
                        if (ticketCost.length > 0) {
                          await addTicketCost();
                        }
                        if (note.length > 0) {
                          await addNote();
                        }
                        await actions.getAdminTripById(
                          adminTripid,
                          adminBooking.data.userDetails.userid
                        );
                        setLoading(false);
                      }}
                    >
                      Save Details
                    </button>
                  )}
              </>
            )}
          </div>
        </div>
      </Popup>
      <Popup condition={tripsBaggage} close={() => setTripsBaggage(false)}>
        {tripsPage ? (
          <>
            <div className="flightBook-baggageMeals-bag-section">
              <div className="flightBook-baggageMeals-bag-title">
                Baggage details
              </div>
              <div className="flightBook-baggageMeals-bag-details-section">
                {/* <div className="flightBook-baggageMeals-bag-route">
                    HYD - BLR
                  </div> */}
                <div className="flightBook-baggageMeals-bag-details">
                  {flightBooking?.baggageDtls?.cabinBaggage ? (
                    <div className="flightBook-baggageMeals-bag-detail">
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="flightBook-baggageMeals-bag-detail-icon"
                      />
                      Cabin baggage:
                      <span>{flightBooking?.baggageDtls?.cabinBaggage}</span>
                    </div>
                  ) : null}
                  {flightBooking?.baggageDtls?.baggage ? (
                    <div className="flightBook-baggageMeals-bag-detail">
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="flightBook-baggageMeals-bag-detail-icon"
                      />
                      Check-in baggage:
                      <span>{flightBooking?.baggageDtls?.baggage}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {Array.isArray(flightBooking?.selectedBaggage) ? (
              <>
                {flightBooking?.selectedBaggage.map((meal) => {
                  return (
                    <>
                      <div>Extra Baggage:</div>
                      <div className="flightBook-meals">
                        {meal.map((meals, s) => {
                          var type =
                            s + 1 <= flightBooking.adults
                              ? "Adult"
                              : s + 1 <=
                                flightBooking.adults + flightBooking.child
                              ? "Child"
                              : "Infant";
                          var indexe =
                            s + 1 <= flightBooking.adults
                              ? s
                              : s + 1 <=
                                flightBooking.adults + flightBooking.child
                              ? s - flightBooking.adults
                              : "Infant";
                          return (
                            <span>
                              {meals.price > 0 ? (
                                <>
                                  {type}-{indexe + 1}: {meals.baggage}KG
                                  -&gt;&nbsp;
                                  <span>
                                    <FontAwesomeIcon
                                      icon={faIndianRupeeSign}
                                      className="icon"
                                    />
                                    {meals.price}
                                  </span>{" "}
                                  {meals.text ? meals.text : ""}
                                </>
                              ) : null}
                            </span>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </>
            ) : null}
          </>
        ) : null}
      </Popup>
      <Popup
        condition={tripsCancellation}
        close={() => setTripsCancellation(false)}
      >
        {Array.isArray(flightBooking?.flight?.MiniFareRules) ? (
          <>
            {flightBooking?.flight?.MiniFareRules &&
            flightBooking?.flight?.MiniFareRules ? (
              <div className="flightBook-cancel">
                <div className="flightBook-cancel-title">
                  Cancellation and date change
                </div>
                <div className="flightBook-cancel-section">
                  <div className="flightBook-cancel-details">
                    <div className="flightBook-cancel-details-title">
                      Cancellation
                    </div>
                    {flightBooking?.flight?.MiniFareRules &&
                      flightBooking?.flight?.MiniFareRules?.[0]
                        ?.map((rule, r) => {
                          if (rule.Type === "Cancellation") {
                            return (
                              <div className="flightBook-cancel-details-text">
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="flightBook-cancel-details-text-icon"
                                />
                                {rule.To === null ||
                                rule.From === null ||
                                rule.Unit === null
                                  ? ""
                                  : rule.To === ""
                                  ? `> ${rule.From} ${rule.Unit} of departure date`
                                  : rule.From === "0"
                                  ? `0- ${rule.To} ${rule.Unit} of departure date`
                                  : `Between ${rule.To} & ${rule.From} ${rule.Unit} of departure date`}
                                <span
                                  className={
                                    rule.To === null ||
                                    rule.From === null ||
                                    rule.Unit === null
                                      ? "flightBook-cancel-details-text-details"
                                      : ""
                                  }
                                >
                                  {rule.Details}
                                </span>
                              </div>
                            );
                          }
                          return null;
                        })
                        .filter((rule, r) => rule !== null)}
                  </div>
                  <div className="flightBook-cancel-details flightBook-cancel-details-last">
                    <div className="flightBook-cancel-details-title">
                      Date change
                    </div>
                    {flightBooking?.flight?.MiniFareRules &&
                      flightBooking?.flight?.MiniFareRules[0]
                        ?.map((rule, r) => {
                          if (rule.Type === "Reissue") {
                            return (
                              <div className="flightBook-cancel-details-text">
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="flightBook-cancel-details-text-icon"
                                />
                                {rule.To === null ||
                                rule.From === null ||
                                rule.Unit === null
                                  ? ""
                                  : rule.To === ""
                                  ? `> ${rule.From} ${rule.Unit} of departure date`
                                  : rule.From === "0"
                                  ? `0- ${rule.To} ${rule.Unit} of departure date`
                                  : `Between ${rule.To} & ${rule.From} ${rule.Unit} of departure date`}
                                <span
                                  className={
                                    rule.To === null ||
                                    rule.From === null ||
                                    rule.Unit === null
                                      ? "flightBook-cancel-details-text-details"
                                      : ""
                                  }
                                >
                                  {rule.Details}
                                </span>
                              </div>
                            );
                          }
                          return null;
                        })
                        .filter((rule, r) => rule !== null)}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </Popup>
      <Popup condition={tripsMeals} close={() => setTripsMeals(false)}>
        {Array.isArray(flightBooking?.selectedMeals) ? (
          <>
            {flightBooking.selectedMeals.map((meal) => {
              return (
                <>
                  <div className="flightBook-meals">
                    <span className="header">Selected Meals</span>
                    {meal.map((meal, s) => {
                      var type =
                        s + 1 <= flightBooking.adults
                          ? "Adult"
                          : s + 1 <= flightBooking.adults + flightBooking.child
                          ? "Child"
                          : "Infant";
                      var indexe =
                        s + 1 <= flightBooking.adults
                          ? s
                          : s + 1 <= flightBooking.adults + flightBooking.child
                          ? s - flightBooking.adults
                          : "Infant";
                      return (
                        <span>
                          {type}-{indexe + 1}:{meal.mealDesc} -&gt;
                          <span>
                            <FontAwesomeIcon
                              icon={faIndianRupeeSign}
                              className="icon"
                            />
                            {meal.price}
                          </span>
                        </span>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </>
        ) : null}
      </Popup>
      <Popup condition={tripsSeat} close={() => setTripsSeat(false)}>
        {id.length > 0 ? (
          <div className="tripsPage-flight-seat">
            Selected Seats:
            {id?.map((ids, index) => {
              return (
                <>
                  Passenger-{index + 1}:&nbsp;
                  {flightBooking
                    ? flightBooking?.seats?.[0]?.length > 0
                      ? flightBooking?.seats?.[0]?.[0]?.[ids]?.Code
                      : ""
                    : ""}
                  &nbsp;
                  {index !== id.length - 1 ? "," : ""}
                </>
              );
            })}
            {/* Selected Seat:{flightBooking ? flightBooking?.seats[0].length > 0 ? flightBooking?.seats[0][0][id[0]]?.Code : '' : ''} */}
          </div>
        ) : null}
      </Popup>
      <Popup
        condition={open}
        close={() => {
          setOpen(false);
          actions.setFlightResJType(0);
        }}
      >
        Please select onward flight first
      </Popup>
      <Popup condition={openTravellers} close={() => setOpenTravellers(false)}>
        <div className="traveller-details-container">
          <div className="traveller-details-header">Traveller Details</div>
          {adminBooking?.data?.travellerDetails ? (
            <>
              {adminFlightTrav &&
                adminFlightTrav?.map((trav, i) => {
                  return (
                    <TravDetails type="Adults" index={i + 1} trav={trav} />
                  );
                })}
            </>
          ) : null}
          {adminBooking?.data?.travellerDetails ? (
            <>
              {adminFlightChild &&
                adminFlightChild?.map((trav, i) => {
                  return (
                    <TravDetails type="Children" index={i + 1} trav={trav} />
                  );
                })}
            </>
          ) : null}
          {adminBooking?.data?.travellerDetails ? (
            <>
              {adminFlightInfant &&
                adminFlightInfant?.map((trav, i) => {
                  return (
                    <TravDetails type="Infants" index={i + 1} trav={trav} />
                  );
                })}
            </>
          ) : null}
        </div>
      </Popup>
      <Popup
        condition={openDelete}
        close={() => {
          setOpenDelete(false);
        }}
      >
        <div className="delete-item">
          <span>Are you sure you want to delete the trip item</span>
          <div>
            <button onClick={handleDelete}>Delete</button>
            <button
              className="back"
              onClick={() => {
                setOpenDelete(false);
              }}
            >
              Go Back
            </button>
          </div>
        </div>
      </Popup>
      <Popup
        condition={openFlightPrice}
        close={() => {
          setOpenFlightPrice(false);
        }}
      >
        <FlightPriceCard
          flightBooking={flightBooking}
          fareData={fareData}
          tripsPage={tripsPage}
        />
      </Popup>
      <Popup
        condition={openFareRules}
        close={() => {
          setOpenFareRules(false);
        }}
      >
        <div className="flightBook-fareRules-section">
          <div dangerouslySetInnerHTML={{ __html: flightBooking?.fareRules }} />
        </div>
      </Popup>
      <Popup
        condition={openAdminPrice}
        close={() => {
          setOpenAdminPrice(false);
        }}
      >
        <FlightPriceCard
          flightBooking={flightBooking}
          fareData={mainfare}
          tripsPage={adminPage}
        />
      </Popup>
      <Popup
        condition={openPriceReCheck}
        close={() => {
          setOpenPriceReCheck(false);
        }}
      >
        <div className="flight-recheck">
          {tripsPage ? (
            <FlightCard
              airlinelogos={airlinelogos}
              flightArr={flightArr}
              flightData={{ data: flightBooking }}
            />
          ) : null}
          {recheckLoad ? (
            <>
              <LoadingProg
                condition={recheckLoad}
                loadingText={"Getting updated prices"}
                progEnd={recheckLoad}
                progTime={90}
              />
            </>
          ) : recheckError ? (
            <div>
              <p className="font-semibold text-center">
                Unable to get updated price for this flight.
              </p>
              <div className="recheck-confirm">
                <button
                  onClick={async () =>
                    await actions.updateFlightBookingDetails(
                      recheckSeatsAvailable
                        ? reCheckData?.flightData?.Fare?.OfferedFare
                        : Math.ceil(
                            reCheckData?.flightData?.Fare?.OfferedFare -
                              reCheckSeats
                          ),
                      // reCheckData?.flightData?.Fare?.OfferedFare,
                      flightId,
                      tripId
                    )
                  }
                >
                  Keep Flight
                </button>
                <button onClick={handleDelete} className="delete-btn">
                  Delete Flight
                </button>
              </div>
            </div>
          ) : (
            <>
              {tripsPage && (
                <div className="recheck-popup-container">
                  <div className="recheck-popup-header">
                    Flight Price Recheck
                  </div>
                  <div className="recheck-block">
                    <div className="recheck-block-header">
                      <div>Flight Details:</div>
                      <div>
                        <span>
                          {
                            flightBooking.flightNew.segments?.[0]
                              ?.originCityName
                          }{" "}
                          to{" "}
                          {flightBooking.flightNew.segments?.[0]?.destCityName}
                        </span>
                      </div>
                    </div>
                    <div className="recheck-block-rates">
                      <div className="old-price pl-[10px] pt-[10px] pb-[10px] pr-[10px]">
                        <div className="old-price-header">Old Rates</div>
                        <div className="old-price-block">
                          <div className="old-price-details">
                            <div className="old-price-details-title">
                              Flight Price:
                            </div>
                            <div className="old-price-details-value">
                              <FontAwesomeIcon
                                icon={faIndianRupeeSign}
                                className="old-price-details-title-icon"
                              />
                              {` ${
                                flightBooking.flight.Fare.OfferedFare
                                  ? Math.ceil(
                                      flightBooking.flight.Fare.OfferedFare
                                    ).toLocaleString("en-IN")
                                  : Math.ceil(
                                      flightBooking.flight.Fare.PublishedFare
                                    ).toLocaleString("en-IN")
                              }`}
                            </div>
                          </div>
                          {fareData?.totalBaggagePrice ? (
                            <div className="old-price-details">
                              <div className="old-price-details-title">
                                Baggage :
                              </div>
                              <div className="old-price-details-value">
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="old-price-details-title-icon"
                                />
                                {` ${fareData?.totalBaggagePrice?.toLocaleString(
                                  "en-IN"
                                )}`}
                              </div>
                            </div>
                          ) : null}
                          {fareData?.totalMealPrice ? (
                            <div className="old-price-details">
                              <div className="old-price-details-title">
                                Meal:
                              </div>
                              <div className="old-price-details-value">
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="old-price-details-title-icon"
                                />
                                {` ${fareData?.totalMealPrice?.toLocaleString(
                                  "en-IN"
                                )}`}
                              </div>
                            </div>
                          ) : null}
                          {fareData?.totalSeatCharges ? (
                            <div className="old-price-details">
                              <div className="old-price-details-title">
                                Seat:
                              </div>
                              <div className="old-price-details-value">
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="old-price-details-title-icon"
                                />
                                {` ${fareData?.totalSeatCharges?.toLocaleString(
                                  "en-IN"
                                )}`}
                              </div>
                            </div>
                          ) : null}
                          {fareData?.totalBaggagePrice ? (
                            <div className="old-price-details">
                              <div className="old-price-details-title">
                                Baggage :
                              </div>
                              <div className="old-price-details-value">
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="old-price-details-title-icon"
                                />
                                {` ${fareData?.totalBaggagePrice?.toLocaleString(
                                  "en-IN"
                                )}`}
                              </div>
                            </div>
                          ) : null}
                          <div className="old-price-details">
                            <div className="old-price-details-title">
                              Service Fee and Taxes :
                            </div>
                            <div className="old-price-details-value">
                              <FontAwesomeIcon
                                icon={faIndianRupeeSign}
                                className="old-price-details-title-icon"
                              />
                              {Math.ceil(
                                (fareData?.totalFareSum * domesticFlight) / 100
                              )}
                            </div>
                          </div>
                          <div className="old-price-details">
                            <div className="old-price-details-title">
                              Total Price :
                            </div>
                            <div className="old-price-details-value">
                              <FontAwesomeIcon
                                icon={faIndianRupeeSign}
                                className="old-price-details-title-icon"
                              />
                              {Math.ceil(fareData?.finalPrice).toLocaleString(
                                "en-IN"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="old-price shadow-md border-[1px] border-solid rounded-md pl-[10px] pt-[10px] pb-[10px] pr-[10px]">
                        <div className="old-price-header">New Rates</div>
                        <div className="old-price-block">
                          <div className="old-price-details">
                            <div className="old-price-details-title">
                              Flight Price:
                            </div>
                            <div className="old-price-details-value">
                              <FontAwesomeIcon
                                icon={faIndianRupeeSign}
                                className="old-price-details-title-icon"
                              />
                              {` ${
                                reCheckData?.flightData?.Fare?.OfferedFare
                                  ? Math.ceil(
                                      reCheckData?.flightData?.Fare?.OfferedFare
                                    ).toLocaleString("en-IN")
                                  : Math.ceil(
                                      reCheckData?.flightData?.Fare
                                        ?.PublishedFare
                                    ).toLocaleString("en-IN")
                              }`}
                            </div>
                          </div>
                          {fareData?.totalBaggagePrice ? (
                            <div className="old-price-details">
                              <div className="old-price-details-title">
                                Baggage :
                              </div>
                              <div className="old-price-details-value">
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="old-price-details-title-icon"
                                />
                                {Math.ceil(reCheckBaggage).toLocaleString(
                                  "en-IN"
                                )}
                              </div>
                            </div>
                          ) : null}
                          {fareData?.totalMealPrice ? (
                            <div className="old-price-details">
                              <div className="old-price-details-title">
                                Meal :
                              </div>
                              <div className="old-price-details-value">
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="old-price-details-title-icon"
                                />
                                {Math.ceil(reCheckMeals).toLocaleString(
                                  "en-IN"
                                )}
                              </div>
                            </div>
                          ) : null}
                          {fareData?.totalSeatCharges ? (
                            <div className="old-price-details">
                              <div className="old-price-details-title">
                                Seat :
                              </div>
                              <div className="old-price-details-value">
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="old-price-details-title-icon"
                                />
                                {` ${
                                  recheckSeatsAvailable
                                    ? reCheckSeats?.toLocaleString("en-IN")
                                    : 0
                                  // reCheckSeats?.toLocaleString("en-IN")
                                }`}
                              </div>
                            </div>
                          ) : null}
                          <div className="old-price-details">
                            <div className="old-price-details-title">
                              Service fee and Taxes :
                            </div>
                            <div className="old-price-details-value">
                              <FontAwesomeIcon
                                icon={faIndianRupeeSign}
                                className="old-price-details-title-icon"
                              />
                              {` ${Math.ceil(
                                ((reCheckData?.flightData?.Fare?.OfferedFare +
                                  reCheckBaggage +
                                  reCheckSeats +
                                  reCheckMeals) *
                                  domesticFlight) /
                                  100
                              )?.toLocaleString("en-IN")}`}
                            </div>
                          </div>
                          <div className="old-price-details">
                            <div className="old-price-details-title">
                              Total Price:
                            </div>
                            <div className="old-price-details-value">
                              <FontAwesomeIcon
                                icon={faIndianRupeeSign}
                                className="old-price-details-title-icon"
                              />
                              {` ${Math.ceil(
                                reCheckData?.flightData?.Fare?.OfferedFare +
                                  reCheckBaggage +
                                  // reCheckSeats +
                                  (recheckSeatsAvailable ? reCheckSeats : 0) +
                                  reCheckMeals +
                                  ((reCheckData?.flightData?.Fare?.OfferedFare +
                                    reCheckBaggage +
                                    reCheckSeats +
                                    reCheckMeals) *
                                    domesticFlight) /
                                    100
                              )?.toLocaleString("en-IN")}`}
                            </div>
                          </div>
                          <div>
                            {recheckSeatsAvailable ? null : (
                              <p>
                                <span className="text-red-500">Note:</span>
                                Selected seats are not available.To book flight
                                with seats,
                                <br /> please delete the flight and add to trip
                                again.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="recheck-confirm">
                      <button
                        onClick={async () =>
                          await actions.updateFlightBookingDetails(
                            recheckSeatsAvailable
                              ? reCheckData?.flightData?.Fare?.OfferedFare
                              : Math.ceil(
                                  reCheckData?.flightData?.Fare?.OfferedFare -
                                    reCheckSeats
                                ),
                            // reCheckData?.flightData?.Fare?.OfferedFare,
                            flightId,
                            tripId
                          )
                        }
                      >
                        Keep Flight
                      </button>
                      <button onClick={handleDelete} className="delete-btn">
                        Delete Flight
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Popup>
      <Popup
        condition={openAdminFareRules}
        close={() => setOpenAdminFareRules(false)}
      >
        {adminPage ? (
          <>
            <div className="flightBook-fareRules-section-admin-header">
              Fare Rules
            </div>
            <div className="flightBook-fareRules-section-admin">
              <div
                dangerouslySetInnerHTML={{ __html: flightBooking?.fareRules }}
              />
            </div>
          </>
        ) : null}
      </Popup>
      <Popup condition={addTravellers} close={() => setAddTravellers(false)}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="overflow-scroll h-[80vh]"
        >
          {Array.from({ length: parseInt(flight?.data?.adults) }, (_, i) => {
            return (
              <div key={`adult-${i}`} className="gap-[10px] mt-[20px]">
                <h1 className="font-bold text-center py-1">Adult-{i + 1}</h1>
                <div className="gap-2 flex-wrap justify-center">
                  <div className="flex gap-[10px] items-center justify-center flex-wrap">
                    <label className="flex flex-col text-[12px]">
                      Title
                      <Controller
                        name={`adults[${i}].gender`}
                        control={control}
                        defaultValue={
                          i === 0 ? userAccountDetails.gender : "Mr"
                        }
                        rules={{ required: "Gender is required" }}
                        render={({ field }) => (
                          <select
                            {...field}
                            className={`${
                              !isFormDisabled
                                ? "border-[1.5px]"
                                : "border-[0px]"
                            } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                            required
                            disabled={isFormDisabled}
                          >
                            <option value="Mr">Mr</option>
                            <option value="Ms">Ms</option>
                            <option value="Mrs">Mrs</option>
                          </select>
                        )}
                      />
                    </label>
                    <label className="flex flex-col text-[12px]">
                      First Name
                      <Controller
                        name={`adults[${i}].firstName`}
                        control={control}
                        defaultValue={
                          i === 0 ? userAccountDetails.firstName : ""
                        }
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`${
                              !isFormDisabled
                                ? "border-[1.5px]"
                                : "border-[0px]"
                            } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                            placeholder="FirstName"
                            required
                            disabled={isFormDisabled}
                          />
                        )}
                      />
                    </label>
                    <label className="flex flex-col text-[12px]">
                      Last Name
                      <Controller
                        name={`adults[${i}].lastName`}
                        control={control}
                        defaultValue={
                          i === 0 ? userAccountDetails.lastName : ""
                        }
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`${
                              !isFormDisabled
                                ? "border-[1.5px]"
                                : "border-[0px]"
                            } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                            placeholder="LastName"
                            required
                            disabled={isFormDisabled}
                          />
                        )}
                      />
                    </label>

                    {i === 0 && (
                      <>
                        <label className="flex flex-col text-[12px]">
                          Email
                          <Controller
                            name={`adults[${i}].email`}
                            control={control}
                            defaultValue={
                              i === 0 ? userAccountDetails.email : ""
                            }
                            render={({ field }) => (
                              <input
                                {...field}
                                className={`${
                                  !isFormDisabled
                                    ? "border-[1.5px]"
                                    : "border-[0px]"
                                } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                                placeholder="Email"
                                required
                                disabled={isFormDisabled}
                              />
                            )}
                          />
                        </label>
                        <label className="flex flex-col text-[12px]">
                          Mobile Number
                          <Controller
                            name={`adults[${i}].mobileNumber`}
                            control={control}
                            defaultValue={
                              i === 0 ? userAccountDetails.mobileNumber : ""
                            }
                            render={({ field }) => (
                              <input
                                {...field}
                                className={`${
                                  !isFormDisabled
                                    ? "border-[1.5px]"
                                    : "border-[0px]"
                                } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                                placeholder="Mobile Number"
                                required
                                disabled={isFormDisabled}
                              />
                            )}
                          />
                        </label>
                      </>
                    )}
                  </div>
                  {isInternational && (
                    <>
                      <div className="flex items-center gap-[10px] my-2 flex-wrap justify-center">
                        <Controller
                          name={`adults[${i}].gender`}
                          control={control}
                          defaultValue={
                            i === 0 ? userAccountDetails.gender : "Mr"
                          }
                          rules={{ required: "Gender is required" }}
                          render={({ field }) => (
                            <select
                              {...field}
                              className={`${
                                !isFormDisabled
                                  ? "border-[1.5px]"
                                  : "border-[0px]"
                              } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal invisible`}
                              required
                              disabled={isFormDisabled}
                            >
                              <option value="Mr">Mr</option>
                              <option value="Ms">Ms</option>
                              <option value="Mrs">Mrs</option>
                            </select>
                          )}
                        />
                        <label className="flex flex-col text-[12px]">
                          Passport Number
                          <Controller
                            name={`adults[${i}].passportNumber`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                className={`${
                                  !isFormDisabled
                                    ? "border-[1.5px]"
                                    : "border-[0px]"
                                } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                                placeholder="Passport Number"
                                required
                                disabled={isFormDisabled}
                              />
                            )}
                          />
                        </label>
                        <label className="flex flex-col text-[12px]">
                          Passport Issue Country
                          <Controller
                            name={`adults[${i}].passportIssueCountry`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                className={`${
                                  !isFormDisabled
                                    ? "border-[1.5px]"
                                    : "border-[0px]"
                                } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                                placeholder="Passport Issue Country"
                                required
                                disabled={isFormDisabled}
                              />
                            )}
                          />
                        </label>
                        <label className="flex flex-col text-[12px]">
                          Passport Issue Date
                          <Controller
                            name={`adults[${i}].passportIssueDate`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="date"
                                className={`${
                                  !isFormDisabled
                                    ? "border-[1.5px]"
                                    : "border-[0px]"
                                } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                                required
                                disabled={isFormDisabled}
                              />
                            )}
                          />
                        </label>
                        <label className="flex flex-col text-[12px]">
                          Passport Expiry Date
                          <Controller
                            name={`adults[${i}].passportExpiryDate`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="date"
                                className={`${
                                  !isFormDisabled
                                    ? "border-[1.5px]"
                                    : "border-[0px]"
                                } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                                required
                                disabled={isFormDisabled}
                              />
                            )}
                          />
                        </label>
                        <label className="flex flex-col text-[12px]">
                          Date of birth
                          <Controller
                            name={`adults[${i}].birthDate`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="date"
                                className={`${
                                  !isFormDisabled
                                    ? "border-[1.5px]"
                                    : "border-[0px]"
                                } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                                required
                                disabled={isFormDisabled}
                              />
                            )}
                          />
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
          {Array.from({ length: parseInt(flight?.data?.child) }, (_, i) => {
            return (
              <div key={`child-${i}`} className="gap-[10px] mt-[20px]">
                <h1 className="font-bold text-center py-1">Child-{i + 1}</h1>
                <div className="flex gap-2 flex-wrap justify-center">
                  <label className="flex flex-col text-[12px]">
                    Title
                    <Controller
                      name={`children[${i}].gender`}
                      control={control}
                      defaultValue="Mr"
                      render={({ field }) => (
                        <select
                          {...field}
                          className={`${
                            !isFormDisabled ? "border-[1.5px]" : "border-[0px]"
                          } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                          required
                          disabled={isFormDisabled}
                        >
                          <option value="Mr">Mr</option>
                          <option value="Ms">Ms</option>
                          <option value="Mrs">Mrs</option>
                        </select>
                      )}
                    />
                  </label>
                  <label className="flex flex-col text-[12px]">
                    First Name
                    <Controller
                      name={`children[${i}].firstName`}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`${
                            !isFormDisabled ? "border-[1.5px]" : "border-[0px]"
                          } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                          placeholder="FirstName"
                          required
                          disabled={isFormDisabled}
                        />
                      )}
                    />
                  </label>
                  <label className="flex flex-col text-[12px]">
                    Last Name
                    <Controller
                      name={`children[${i}].lastName`}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`${
                            !isFormDisabled ? "border-[1.5px]" : "border-[0px]"
                          } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                          placeholder="LastName"
                          required
                          disabled={isFormDisabled}
                        />
                      )}
                    />
                  </label>
                  {isInternational && (
                    <>
                      <label className="flex flex-col text-[12px] flex-wrap justify-center">
                        Passport Number
                        <Controller
                          name={`children[${i}].passportNumber`}
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              className={`${
                                !isFormDisabled
                                  ? "border-[1.5px]"
                                  : "border-[0px]"
                              } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                              placeholder="Passport Number"
                              required
                              disabled={isFormDisabled}
                            />
                          )}
                        />
                      </label>
                      <label className="flex flex-col text-[12px]">
                        Passport Issue Country
                        <Controller
                          name={`children[${i}].passportIssueCountry`}
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              className={`${
                                !isFormDisabled
                                  ? "border-[1.5px]"
                                  : "border-[0px]"
                              } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                              placeholder="Passport Issue Country"
                              required
                              disabled={isFormDisabled}
                            />
                          )}
                        />
                      </label>
                      <label className="flex flex-col text-[12px]">
                        Passport Issue Date
                        <Controller
                          name={`children[${i}].passportIssueDate`}
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="date"
                              className={`${
                                !isFormDisabled
                                  ? "border-[1.5px]"
                                  : "border-[0px]"
                              } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                              required
                              disabled={isFormDisabled}
                            />
                          )}
                        />
                      </label>
                      <label className="flex flex-col text-[12px]">
                        Passport Expiry Date
                        <Controller
                          name={`children[${i}].passportExpiryDate`}
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="date"
                              className={`${
                                !isFormDisabled
                                  ? "border-[1.5px]"
                                  : "border-[0px]"
                              } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                              required
                              disabled={isFormDisabled}
                            />
                          )}
                        />
                      </label>
                      <label className="flex flex-col text-[12px]">
                        Date of birth
                        <Controller
                          name={`children[${i}].birthDate`}
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="date"
                              className={`${
                                !isFormDisabled
                                  ? "border-[1.5px]"
                                  : "border-[0px]"
                              } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                              required
                              disabled={isFormDisabled}
                            />
                          )}
                        />
                      </label>
                    </>
                  )}
                </div>
              </div>
            );
          })}
          {Array.from({ length: parseInt(flight?.data?.infant) }, (_, i) => (
            <div key={`infant-${i}`} className="gap-[10px] mt-[20px]">
              <h1 className="font-bold text-center py-1">Infant-{i + 1}</h1>
              <div className="flex gap-2 flex-wrap justify-center">
                <label className="flex flex-col text-[12px]">
                  Title
                  <Controller
                    name={`infants[${i}].gender`}
                    control={control}
                    defaultValue={"Mr"}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`${
                          !isFormDisabled ? "border-[1.5px]" : "border-[0px]"
                        } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                        required
                        disabled={isFormDisabled}
                      >
                        <option value="Mr">Mr</option>
                        <option value="Ms">Ms</option>
                        <option value="Mrs">Mrs</option>
                      </select>
                    )}
                  />
                </label>
                <label className="flex flex-col text-[12px]">
                  First Name
                  <Controller
                    name={`infants[${i}].firstName`}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`${
                          !isFormDisabled ? "border-[1.5px]" : "border-[0px]"
                        } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                        placeholder="FirstName"
                        required
                        disabled={isFormDisabled}
                      />
                    )}
                  />
                </label>
                <label className="flex flex-col text-[12px]">
                  Last Name
                  <Controller
                    name={`infants[${i}].lastName`}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`${
                          !isFormDisabled ? "border-[1.5px]" : "border-[0px]"
                        } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                        placeholder="LastName"
                        required
                        disabled={isFormDisabled}
                      />
                    )}
                  />
                </label>

                {isInternational && (
                  <>
                    <label className="flex flex-col flex-wrap justify-center text-[12px]">
                      Passport Number
                      <Controller
                        name={`infants[${i}].passportNumber`}
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`${
                              !isFormDisabled
                                ? "border-[1.5px]"
                                : "border-[0px]"
                            } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                            placeholder="Passport Number"
                            required
                            disabled={isFormDisabled}
                          />
                        )}
                      />
                    </label>
                    <label className="flex flex-col text-[12px]">
                      Passport Issue Country
                      <Controller
                        name={`infants[${i}].passportIssueCountry`}
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`${
                              !isFormDisabled
                                ? "border-[1.5px]"
                                : "border-[0px]"
                            } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                            placeholder="Passport Issue Country"
                            required
                            disabled={isFormDisabled}
                          />
                        )}
                      />
                    </label>
                    <label className="flex flex-col text-[12px]">
                      Passport Issue Date
                      <Controller
                        name={`infants[${i}].passportIssueDate`}
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="date"
                            className={`${
                              !isFormDisabled
                                ? "border-[1.5px]"
                                : "border-[0px]"
                            } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                            required
                            disabled={isFormDisabled}
                          />
                        )}
                      />
                    </label>
                    <label className="flex flex-col text-[12px]">
                      Passport Issue Country
                      <Controller
                        name={`infants[${i}].passportExpiryDate`}
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="date"
                            className={`${
                              !isFormDisabled
                                ? "border-[1.5px]"
                                : "border-[0px]"
                            } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                            required
                            disabled={isFormDisabled}
                          />
                        )}
                      />
                    </label>
                    <label className="flex flex-col text-[12px]">
                      Date of birth
                      <Controller
                        name={`infants[${i}].birthDate`}
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="date"
                            className={`${
                              !isFormDisabled
                                ? "border-[1.5px]"
                                : "border-[0px]"
                            } border-solid focus:outline-none border-black rounded-md pl-2 py-[2px] text-[16px] font-normal`}
                            required
                            disabled={isFormDisabled}
                          />
                        )}
                      />
                    </label>
                  </>
                )}
              </div>
            </div>
          ))}
          <div className="flex gap-2 justify-center items-center mt-3">
            {flightStatus?.status === "Not Submitted" ? (
              <>
                {tripData?.data?.travellerDetails &&
                tripData?.data?.travellerDetails[flight?.id] ? (
                  !isFormDisabled ? (
                    <Button
                      type="submit"
                      variant="contained"
                      className="bg-black"
                      size="small"
                      // onClick={handleSubmit(onSubmit)}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent form submission
                        setIsFormDisabled(false);
                      }}
                      variant="contained"
                      className="!bg-gray-300 text-black"
                    >
                      Edit
                    </Button>
                  )
                ) : (
                  <Button
                    // onClick={handleSubmit(onSubmit)}
                    type="submit"
                    variant="contained"
                    className="bg-black"
                    size="small"
                  >
                    Save
                  </Button>
                )}
              </>
            ) : null}
          </div>
        </form>
      </Popup>
      <div
        className={
          bookingFlight &&
          bookingFlight[flightResJType]?.flight.flightCodeStr ===
            flightGrp?.[0]?.flightCodeStr &&
          !bookingPage
            ? "flightResults-list-flightCard flightResults-list-flightCard-selected"
            : "flightResults-list-flightCard"
        }
        style={flightStatus ? getFlightStatusStyle(flightStatus.status) : null}
      >
        <div
          className={
            flightArr?.[0]?.segments.length > 1
              ? "flightResults-list-flightCard-segment-block flightResults-list-flightCard-segment-seperate"
              : "flightResults-list-flightCard-segment-block"
          }
        >
          {flightArr?.[0]?.segments.map((segment, sg) => {
            console.log(segment);
            var flightCode = "";
            segment.flightCodes.forEach((code, c) => {
              if (c === segment.flightCodes.length - 1) {
                flightCode += code;
              } else {
                flightCode += `${code}, `;
              }
            });
            var airlinename = segment.airlineName;

            var airline = airlinelogos?.filter((a) => {
              return airlinename.toLowerCase() === a.id;
            });

            return (
              <>
                <div className="flightResults-list-flightCard-airline-block">
                  <div className="flightResults-list-flightCard-airline">
                    {airline?.[0] ? (
                      <div className="flightResults-list-flightCard-logo">
                        <span
                          style={{
                            backgroundImage: `url(${airline?.[0]?.url})`,
                          }}
                        ></span>
                      </div>
                    ) : (
                      <div className="flightResults-list-flightCard-logo">
                        <span>
                          <FontAwesomeIcon
                            icon={faPlaneDeparture}
                            className="flightResults-list-flightCard-logo-icon"
                          />
                        </span>
                      </div>
                    )}
                    {`${segment.airlineName}`}
                    <span>&nbsp;&nbsp;({flightCode})</span>
                  </div>

                  {bookingPage || tripsPage ? (
                    <div className="flightResults-list-flightCard-depDate-block">
                      <div className="flightResults-list-flightCard-depDate">
                        {segment.depTimeDate.toString().slice(4, 10)}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  className={
                    // bookingPage &&
                    //   flightArr[0].segments.length > 1 &&
                    //   segIndex === sg
                    false
                      ? "flightResults-list-flightCard-segment-section flightResults-list-flightCard-segment-section-selected"
                      : "flightResults-list-flightCard-segment-section"
                  }
                  // onClick={
                  //   bookingPage && flightArr[0].segments.length > 1
                  //     ? () => setSegIndex(sg)
                  //     : null
                  // }
                >
                  <div className="flightResults-list-flightCard-arrDep">
                    <div className="flightResults-list-flightCard-dep">
                      <div className="flightResults-list-flightCard-depTime">
                        {segment.depTime}
                      </div>
                      <div className="flightResults-list-flightCard-depCity">
                        {segment.originAirportCode}
                      </div>
                    </div>
                    <div className="flightResults-list-flightCard-duration">
                      <div
                        className="flightResult-list-flightCard-duration-stopPts"
                        onClick={() => {
                          setStopDtls(segment.segRoutes);
                          setShowStopDtls(true);
                        }}
                      >
                        <div className="flightResult-list-flightCard-duration-stopType">
                          {segment.stopOverPts.length === 0
                            ? "Direct"
                            : `${
                                segment.stopOverPts.length > 1
                                  ? `${segment.stopOverPts.length} stops`
                                  : "1 stop"
                              }`}
                          {segment.stopOverPts.length !== 0 ? (
                            <FontAwesomeIcon
                              icon={faChevronUp}
                              className="flightResult-list-flightCard-duration-stopType-icon"
                            />
                          ) : null}
                        </div>
                        {/* {segment.stopOverPts.map((stopPt, sp) => {
                        if (
                          sp === 0 ||
                          (sp > 0 && segment.stopOverPts[sp - 1].charNum <= 12)
                        ) {
                          if (stopPt.charNum > 12) {
                            return (
                              <div className="flightResult-list-flightCard-duration-stopPt">
                                {stopPt.cityName.slice(
                                  0,
                                  stopPt.cityName.length - (stopPt.charNum - 12)
                                ) + " ..."}
                              </div>
                            );
                          } else if (stopPt.charNum <= 12) {
                            return (
                              <div className="flightResult-list-flightCard-duration-stopPt">
                                {`${stopPt.cityName}${
                                  segment.stopOverPts.length > 1 ? "," : ""
                                }`}
                              </div>
                            );
                          }
                        }
                        return "";
                      })} */}
                      </div>
                      <div className="flightResults-list-flightCard-duration-time">
                        {segment.finalTime === "NaNm"
                          ? segment.duration
                          : segment.finalTime}
                      </div>
                    </div>
                    <div className="flightResults-list-flightCard-arr-section">
                      <div className="flightResults-list-flightCard-dep flightResults-list-flightCard-arr">
                        <div className="flightResults-list-flightCard-depTime">
                          {segment.arrTime}
                        </div>
                        <div className="flightResults-list-flightCard-depCity">
                          {segment.destAirportCode}
                        </div>
                      </div>
                      {segment.arrAfterDays > 0 ? (
                        <div className="flightResults-list-flightCard-depTime-afterDays">
                          <div className="flightResults-list-flightCard-depTime-afterDays-num">{`+ ${segment.arrAfterDays}`}</div>
                          <div>{`${
                            segment.arrAfterDays > 1 ? "Days" : "Day"
                          }`}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {bookingPage ? (
                    <div className="flightResults-list-flightCard-airportNames">
                      <div className="flightResults-list-flightCard-airportName">
                        <div className="flightResults-list-flightCard-cityName">
                          {segment.originCityName}
                        </div>
                        {segment.originAirportName}
                      </div>
                      <div className="flightResults-list-flightCard-airportName flightResults-list-flightCard-airportName-dest">
                        <div className="flightResults-list-flightCard-cityName">
                          {segment.destCityName}
                        </div>
                        {segment.destAirportName}
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            );
          })}
        </div>

        <div className="flightResults-list-flightCard-header">
          {/* <div className="flightResults-list-flightCard-airline">
            {`${airlineName}`}
            <span>({flightCode})</span>
          </div> */}

          {!bookingPage ? (
            <>
              <div className="flightResults-list-flightCard-baggageCancel-block">
                <div
                  className="flightResults-list-flightCard-baggageCancel"
                  onClick={() => {
                    setBaggage(true);
                    setBaggageDtls({
                      baggage: flightArr?.[0]?.segments?.[0]?.baggage,
                      cabinBaggage: flightArr?.[0]?.segments?.[0]?.cabinBaggage,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSuitcaseRolling} />
                </div>
                <div
                  className="flightResults-list-flightCard-baggageCancel"
                  onClick={async () => {
                    // console.log(flightArr[index].resultIndex);
                    setCancellation(true);
                    if (flightArr?.[0]?.fareRules.length === 0) {
                      var details = await actions.fetchFareRule(
                        flightArr?.[0]?.resultIndex,
                        flightArr?.[0]?.segments?.[0]?.airlineName,
                        600
                      );
                      setCancelDtls(details);
                    } else {
                      setCancelDtls(flightArr?.[0]?.fareRules);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faCalendarXmark} />
                </div>
              </div>

              <div className="flightResults-list-flightCard-fare">
                <div className="flightResults-list-flightCard-price">
                  <FontAwesomeIcon
                    icon={faIndianRupeeSign}
                    className="flightResults-list-flightCard-price-icon"
                  />
                  {`${flightArr?.[0]?.fare.toLocaleString("en-IN")}`}
                </div>
                <div className="flightResults-list-flightCard-book">
                  <button
                    onClick={() => {
                      if (flightResJType === 1 && !bookingFlight.length > 0) {
                        setOpen(true);
                        return;
                      } else {
                        //actions.fetchFareRule(flightArr[0].resultIndex, "Indigo", 600)
                        actions.fetchFlightBookData(
                          flightArr?.[0]?.resultIndex,
                          flightGrp?.[0],
                          {
                            baggage:
                              flightArr?.[0]?.segments[segmentIndex].baggage,
                            cabinBaggage:
                              flightArr?.[0]?.segments[segmentIndex]
                                .cabinBaggage,
                          },
                          index
                        );
                      }
                      removeFilters();
                    }}
                  >
                    {flightResList.length > 1 ? "Select" : "Book"}
                  </button>
                </div>
              </div>
            </>
          ) : !tripsPage && !adminPage ? (
            <>
              <div className="flightResults-list-flightCard-fTypeBadge">
                {bookingFlight[index].flightNew.fareType}
              </div>
              <div className="flightResults-list-flightCard-travellers">
                {`${bookingFlight[index].adults} ${
                  bookingFlight[index].adults > 1 ? "Adults" : "Adults"
                } ${
                  bookingFlight[index].child > 0
                    ? `, ${bookingFlight[index].child} ${
                        bookingFlight[index].child > 1 ? "children" : "child"
                      }`
                    : ""
                }   ${
                  bookingFlight[index].infant > 0
                    ? `, ${bookingFlight[index].infant} ${
                        bookingFlight[index].infant > 1 ? "infants" : "infant"
                      }`
                    : ""
                }`}
              </div>
              <div className="flightResults-list-flightCard-cabinClass">
                {flightArr?.[0]?.segments?.[0]?.cabinClass}
              </div>
            </>
          ) : (
            <>
              <div className="flightResults-list-flightCard-fTypeBadge">
                {flightBooking.flightNew.fareType}
              </div>
              <div className="flightResults-list-flightCard-travellers">{`${Number(
                flightBooking.adults
              )} ${Number(flightBooking.adults) > 1 ? "Adults" : "Adults"} ${
                Number(flightBooking.child) > 0
                  ? `, ${Number(flightBooking.child)} ${
                      Number(flightBooking.child) > 1 ? "children" : "child"
                    }`
                  : ""
              }   ${
                Number(flightBooking.infant) > 0
                  ? `, ${Number(flightBooking.infant)} ${
                      Number(flightBooking.infant) > 1 ? "infants" : "infant"
                    }`
                  : ""
              }`}</div>
              <div className="flightResults-list-flightCard-cabinClass">
                {flightArr?.[0]?.segments?.[0]?.cabinClass}
              </div>
              {/* {bookingPage && tripsPage ? (
                  <div className="flightResults-list-flightCard-depDate-block">
                    <div className="flightResults-list-flightCard-depDate">
                      {flightArr[0].segments[0].depTimeDate.toString().slice(4, 10)}
                    </div>
                  </div>
                ) : null} */}
            </>
          )}
        </div>
        <div className="flightBooking-admin-details">
          {adminPage ? (
            <>
              <div className="flightBooking-admin-details-card">
                <div className="flightBooking-admin-details-header">
                  Baggage
                </div>
                {Array.isArray(fData?.baggagePrice) ? (
                  <>
                    {fData?.baggageDtls?.cabinBaggage ? (
                      <div className="flightBook-baggageMeals-bag-detail">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="flightBook-baggageMeals-bag-detail-icon"
                        />
                        Cabin baggage:
                        <span>{fData?.baggageDtls?.cabinBaggage}</span>
                      </div>
                    ) : null}
                    {fData?.baggageDtls?.baggage ? (
                      <div className="flightBook-baggageMeals-bag-detail">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="flightBook-baggageMeals-bag-detail-icon"
                        />
                        Check-in baggage:
                        <span>{fData?.baggageDtls?.baggage}&nbsp;KG</span>
                      </div>
                    ) : null}
                    {Array.isArray(fData?.selectedBaggage) ? (
                      <>
                        {fData?.selectedBaggage.map((meal) => {
                          return (
                            <>
                              <div className="flightBook-meals">
                                {meal.map((meals, i) => {
                                  return (
                                    <span>
                                      {meals.price > 0 ? (
                                        <>
                                          Passenger-{i + 1}:{meals.baggage}{" "}
                                          -&gt;{meals.price}
                                        </>
                                      ) : null}
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
              <div className="flightBooking-admin-details-card">
                <div className="flightBooking-admin-details-header">
                  Selected Seats
                </div>
                {adminId.length > 0 ? (
                  <div className="tripsPage-flight-seat">
                    {adminId?.map((ids, index) => {
                      return (
                        <>
                          Passenger-{index + 1}&nbsp;
                          {fData
                            ? fData?.seats?.[0]?.length > 0
                              ? fData?.seats?.[0]?.[0]?.[ids]?.Code
                              : ""
                            : ""}
                          &nbsp;
                          {index !== adminId.length - 1 ? "," : ""}
                        </>
                      );
                    })}
                    {/* Selected Seat:{flightBooking ? flightBooking?.seats[0].length > 0 ? flightBooking?.seats[0][0][id[0]]?.Code : '' : ''} */}
                  </div>
                ) : (
                  <div>No Seats Selected</div>
                )}
              </div>
              <div className="flightBooking-admin-details-card">
                <div className="flightBooking-admin-details-header">
                  Selected Meal
                </div>
                {Array.isArray(fData?.selectedMeals) ? (
                  <>
                    {fData?.selectedMeals.map((meal) => {
                      return (
                        <>
                          <div className="flightBook-meals">
                            {meal.map((meals, i) => {
                              return (
                                <span>
                                  {meals.price > 0 ? (
                                    <>
                                      Passenger-{i + 1}:{meals.mealDesc} -&gt;
                                      {meals.price}
                                    </>
                                  ) : null}
                                </span>
                              );
                            })}
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        {bookingPage && tripsPage ? (
          <>
            <div className="flightRes-tripsPage-details">
              <FontAwesomeIcon
                icon={faSuitcaseRolling}
                onClick={(e) => setBaggageOpen(e)}
                className="flightRes-tripsPage-details-icon"
              />
              <FontAwesomeIcon
                icon={faCalendarXmark}
                onClick={(e) => setCancellationOpen(e)}
                className="flightRes-tripsPage-details-icon"
              />
              <FontAwesomeIcon
                icon={faCutlery}
                onClick={(e) => setMealsOpen(e)}
                className="flightRes-tripsPage-details-icon"
              />
              <FontAwesomeIcon
                icon={faCircleInfo}
                onClick={(e) => setFareRules(e)}
                className="flightRes-tripsPage-details-icon"
              />
              <FontAwesomeIcon
                icon={faChair}
                onClick={(e) => setSeatsOpen(e)}
                className="flightRes-tripsPage-details-icon"
              />
            </div>
            {userPage ? null : (
              <>
                {isTimeReCheck ? (
                  <div className="recheck-rates">
                    <div className="recheck-bg"></div>
                    <div className="recheck-content">
                      <button
                        onClick={async () => {
                          setOpenDelete(true);
                          setDeleteType("flights");
                          setDeleteId(flightId);
                          //await actions.updateFlightBookingDetails(7907, flightId, tripId)
                        }}
                      >
                        <MdDelete color="red" />
                      </button>
                      <button
                        onClick={async () => {
                          setReCheckLoading(true);
                          setOpenPriceReCheck(true);
                          var data = await actions.getFlightUpdatedDetails(
                            flightBooking.flightRequest,
                            flightBooking.flight
                          );
                          const selectedSeats = {
                            "1A": {
                              Destination: "COK",
                              Deck: 1,
                              SeatNo: "E",
                              Description: 2,
                              FlightNumber: "635",
                              AvailablityTyp: "1",
                            },
                            "1B": {
                              RowNo: "8",
                              Destination: "COK",
                              CraftType: "A320-186",
                              SeatWayType: 2,
                              Code: "8F",
                              AvailablityTyp: "3",
                            },
                          };
                          // console.log(
                          //   areSeatsAvailable(
                          //     data.ssrData.SeatDynamic[0].SegmentSeat[0].RowSeats,
                          //     selectedSeats
                          //   )
                          // );
                          getUpdatedFares(data?.ssrData);
                          setReCheckData(data);
                          areSeatsAvailable(
                            data?.ssrData?.SeatDynamic !== undefined
                              ? data?.ssrData?.SeatDynamic?.[0]
                                  ?.SegmentSeat?.[0]?.RowSeats
                              : null,
                            flightBooking?.seats !== undefined
                              ? flightBooking?.seats?.[0]?.[0]
                              : null
                            // selectedSeats
                          );
                          setReCheckLoading(false);
                          setDeleteType("flights");
                          setDeleteId(flightId);
                        }}
                      >
                        ReCheck
                      </button>
                    </div>
                  </div>
                ) : null}
              </>
            )}
            <div className="seperate"></div>
            <div className="timestamp">
              <div>
                {flightStatus?.requestStatus === "Pending" ||
                flightStatus?.status === "Submitted" ||
                flightStatus?.status === "Booked" ||
                flightStatus?.requestStatus === "Approved" ? null : (
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="delete-icon"
                    onClick={() => {
                      setOpenDelete(true);
                      setDeleteType("flights");
                      setDeleteId(flightId);
                    }}
                  />
                )}
              </div>
              <div
                onClick={() => {
                  console.log(tripData);
                  setTimeStampData();
                  setAllTimeStamp(true);
                }}
              >
                <LuAlarmClock size={15} className="cursor-pointer" />
              </div>
              <div>
                {/* Added:&nbsp;
                <span>{timeStamp.toString().slice(4, 25)}</span> */}
              </div>
              {/* {updatedAt !== undefined ? (
                <div>
                  Updated:&nbsp;
                  <span>
                    {new Date(updatedAt * 1000).toString().slice(4, 25)}
                  </span>
                </div>
              ) : null} */}
              <div className="flightResults-list-flightCard-price-trips">
                Total Price:
                <FontAwesomeIcon
                  icon={faIndianRupeeSign}
                  className="flightResults-list-flightCard-price-icon"
                />
                {`${Math.ceil(
                  flightBooking?.totalFare +
                    flightBooking?.finalFlightServiceCharge +
                    flightBooking?.gstInFinalserviceCharge
                )?.toLocaleString("en-IN")}`}
                &nbsp;
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="info-icon"
                  onClick={() => {
                    setOpenFlightPrice((prev) => !prev);
                  }}
                />
              </div>
            </div>
            <div className="seperate"></div>
            <div className="flightResults-tripsPage">
              <div className="request-status">
                Approval Status:
                <span
                  style={{
                    background: reqColor?.[0]
                      ? reqColor?.[0]?.color
                      : "#808080",
                  }}
                >
                  {flightStatus?.requestStatus}
                </span>
              </div>
              {flightStatus ? (
                <div className="flight-main-status">
                  {flightStatus?.status ? (
                    <>
                      <div className="flightStatus">
                        Booking Status:
                        <span style={{ background: color?.[0]?.color }}>
                          {flightStatus?.status}
                        </span>
                      </div>
                      {downloadUrl ? (
                        <div
                          className="flightResults-list-flightCard-download"
                          onClick={downloadDoc}
                        >
                          Voucher&nbsp;
                          <FontAwesomeIcon icon={faDownload} />
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="flightStatus" style={{ background: "#808080" }}>
                  Booking Status
                  <span style={{ background: "#808080" }}>Not Submitted</span>
                </div>
              )}
              {tripsPage ? (
                <div>
                  {userPage ? null : (
                    <>
                      {tripData?.data?.travellerDetails &&
                      tripData?.data?.travellerDetails[flight.id] ? (
                        <Button
                          sx={{ position: "static", fontSize: "11px" }}
                          className="!bg-[#94d2bd] !text-black"
                          size="small"
                          onClick={() => {
                            console.log(flight.id);

                            setNewTravellerDetails(
                              () => tripData?.data?.travellerDetails[flight.id]
                            );
                            setAddTravellers(true);
                          }}
                        >
                          View Travellers
                        </Button>
                      ) : (
                        <Button
                          sx={{ position: "static", fontSize: "11px" }}
                          className="!bg-[#0a9396] !text-white"
                          size="small"
                          onClick={() => {
                            console.log(flight.id);

                            setAddTravellers(true);
                          }}
                        >
                          Add Travellers
                        </Button>
                      )}
                    </>
                  )}
                </div>
              ) : null}
            </div>
            {userPage ? (
              <>
                {isThere && (
                  <button onClick={() => {}}>
                    <PDFDownloadLink
                      document={
                        <InvoicePdf1
                          type="Flight"
                          flight={flight}
                          userAccountDetails={user}
                          invoiceId={isThere.invoiceId}
                          tripData={userPage ? adminTripdata : tripData}
                          totalFlight={totalFlight?.[0]}
                          adminTripdata={adminTripdata}
                        />
                      }
                      fileName={`${
                        userPage
                          ? adminTripdata?.data?.name
                          : tripData.data?.name
                      }_Invoice.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <div className="font-semibold flex items-center gap-1 border-[1px] border-black rounded-[0.8rem] p-[4pt] text-[10pt]">
                            <p>Invoice PDF</p>
                            <FontAwesomeIcon icon={faDownload} />
                          </div>
                        ) : (
                          <div className="font-semibold flex items-center gap-1 border-[1px] border-black rounded-[0.8rem] p-[4pt] text-[10pt]">
                            <p>Invoice PDF</p>
                            <FontAwesomeIcon icon={faDownload} />
                          </div>
                        )
                      }
                    </PDFDownloadLink>
                  </button>
                )}
              </>
            ) : (
              <>
                {isThere && (
                  <button onClick={() => {}}>
                    <PDFDownloadLink
                      document={
                        <InvoicePdf1
                          type="Flight"
                          flight={flight}
                          userAccountDetails={userAccountDetails}
                          invoiceId={isThere.invoiceId}
                          tripData={tripData}
                          totalFlight={totalFlight?.[0]}
                        />
                      }
                      fileName={`${tripData.data?.name}_Invoice.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <div className="font-semibold flex items-center gap-1 border-[1px] border-black rounded-[0.8rem] p-[4pt] text-[10pt]">
                            <p>Invoice PDF</p>
                            <FontAwesomeIcon icon={faDownload} />
                          </div>
                        ) : (
                          <div className="font-semibold flex items-center gap-1 border-[1px] border-black rounded-[0.8rem] p-[4pt] text-[10pt]">
                            <p>Invoice PDF</p>
                            <FontAwesomeIcon icon={faDownload} />
                          </div>
                        )
                      }
                    </PDFDownloadLink>
                  </button>
                )}
              </>
            )}
          </>
        ) : null}
        {flightArr.length > 1 && !tripsPage ? (
          <div className="flightResults-list-flightCard-viewPrices">
            <div
              className="flightResults-list-flightCard-viewPrices-section"
              onClick={(e) => {
                toggle(e);
              }}
            >
              View Prices{" "}
              <FontAwesomeIcon
                icon={faChevronDown}
                className={
                  isOpen
                    ? "flightResults-list-flightCard-viewPrices-icon flightResults-list-flightCard-viewPrices-icon-open"
                    : "flightResults-list-flightCard-viewPrices-icon"
                }
              />
            </div>
          </div>
        ) : null}
        <div
          className="flightResults-list-flightCard-fareList"
          id={`flightIndex${index}`}
          style={{
            display: "none",
            cursor: "default",
          }}
        >
          {flightArr.map((flight, f) => {
            if (f > 0) {
              return (
                <div
                  className={
                    f === flightArr.length - 1
                      ? "flightResults-list-flightCard-segment-fareList flightResults-list-flightCard-segment-fareList-last"
                      : "flightResults-list-flightCard-segment-fareList"
                  }
                >
                  <div className="flightResults-list-flightCard-fareType">
                    {`${flight.fareType}`}
                  </div>
                  <div className="flightResults-list-flightCard-header">
                    <div className="flightResults-list-flightCard-baggageCancel-block">
                      <div
                        className="flightResults-list-flightCard-baggageCancel"
                        onClick={() => {
                          setBaggage(true);
                          setBaggageDtls({
                            baggage: flight.segments?.[0]?.baggage,
                            cabinBaggage: flight.segments?.[0]?.cabinBaggage,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faSuitcaseRolling} />
                      </div>
                      <div
                        className="flightResults-list-flightCard-baggageCancel"
                        onClick={async () => {
                          setCancellation(true);
                          if (flight.fareRules.length === 0) {
                            var details = await actions.fetchFareRule(
                              flight.resultIndex,
                              flightArr[f].segments?.[0]?.airlineName,
                              600
                            );
                            setCancelDtls(details);
                          } else {
                            setCancelDtls(flightArr[f].fareRules);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faCalendarXmark} />
                      </div>
                    </div>
                    <div className="flightResults-list-flightCard-fare">
                      <div className="flightResults-list-flightCard-price">
                        <FontAwesomeIcon
                          icon={faIndianRupeeSign}
                          className="flightResults-list-flightCard-price-icon"
                        />
                        {`${flight.fare.toLocaleString("en-IN")}`}
                      </div>

                      {!bookingPage ? (
                        <div className="flightResults-list-flightCard-book">
                          <button
                            onClick={() => {
                              console.log(flightArr[f].resultIndex);
                              if (flightResJType === 1 && !bookingFlight) {
                                setOpen(true);
                                return;
                              } else {
                                //actions.fetchFareRule(flightArr[0].resultIndex, "Indigo", 600)
                                actions.fetchFlightBookData(
                                  flightArr[f].resultIndex,
                                  flightGrp[f],
                                  {
                                    baggage:
                                      flightArr[f].segments?.[0]?.baggage,
                                    cabinBaggage:
                                      flightArr[f].segments?.[0]?.cabinBaggage,
                                  },
                                  index
                                );
                              }
                              removeFilters();
                            }}
                          >
                            {flightResList.length > 1 ? "Select" : "Book"}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
        {adminPage ? (
          <>
            <div className="flightBooking-admin">
              <div className="request-status">
                Approval Status:
                <span
                  style={{
                    background: reqColor?.[0]
                      ? reqColor?.[0]?.color
                      : "#808080",
                  }}
                >
                  {flightData?.requestStatus}
                </span>
              </div>
              <div className="hotelType">
                <div className="hotelStatus">
                  Booking Status:
                  <span
                    style={{
                      background: adColor?.[0]
                        ? adColor?.[0]?.color
                        : "#808080",
                    }}
                  >
                    {flightData.status}
                  </span>
                </div>
              </div>
              <div className="flightResults-list-flightCard-price">
                <FontAwesomeIcon
                  icon={faIndianRupeeSign}
                  className="flightResults-list-flightCard-price-icon"
                />
                {`${Math.ceil(
                  flightBooking.totalFare +
                    flightBooking.finalFlightServiceCharge +
                    flightBooking.gstInFinalserviceCharge
                )}`}
                &nbsp;
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  onClick={() => setOpenAdminPrice(true)}
                  className="info-icon"
                />
              </div>
            </div>
            <div className="seperate"></div>
            <div className="flightBooking-admin-upload">
              {flightData.downloadURL ? (
                <div className="flight-file">
                  <div onClick={handleAdminDownload}>
                    <FontAwesomeIcon icon={faDownload} onClick={downloadDoc} />`
                    {downloadName?.slice(0, 14)}...`
                  </div>
                </div>
              ) : null}
              {flightData.ticketCost ? (
                <div className="flight-ticket-cost">
                  Ticket Cost:{" "}
                  <span>
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="hotelInfo-roomDtls-room-price-icon"
                    />{" "}
                    {flightData.ticketCost}
                  </span>
                </div>
              ) : null}
              {flightData.note ? (
                <div className="flight-ticket-cost">
                  Note: {flightData.note}
                </div>
              ) : null}
              {flightData?.note ||
              flightData?.ticketCost ||
              flightData?.downloadURL ? (
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  className="upload-icon"
                  onClick={() => {
                    setOpenUpload(true);
                    setStatus(flightData.status);
                    setNote(flightData.note ? flightData.note : "");
                    setTicketCost(
                      flightData.ticketCost ? flightData.ticketCost : ""
                    );
                    setHotelId(flightData.id);
                  }}
                />
              ) : null}
            </div>
            {flightData?.note ||
            flightData?.ticketCost ||
            flightData?.downloadURL ? (
              <div className="seperate"></div>
            ) : null}

            <div className="flightBooking-admin">
              <div
                className="hotel-card-upload"
                onClick={() => {
                  setOpenUpload(true);
                  setHotelId(flightData?.id);
                }}
              >
                <FontAwesomeIcon icon={faUpload} />
                &nbsp; Booking
              </div>
              <div className="hotel-travellers">
                <button
                  onClick={() => {
                    setOpenTravellers(true);
                    setNewFlightid(flightData?.id);
                    console.log(flightData);
                    //setTripId(hotel.id)
                  }}
                >
                  Traveller Details
                </button>
              </div>
              <div className="hotel-travellers">
                <button
                  onClick={() => {
                    setOpenAdminFareRules(true);
                    //setTripId(hotel.id)
                  }}
                >
                  Fare Rules
                </button>
              </div>
            </div>
          </>
        ) : null}
        {userPage && (
          <>
            <div className="hotel-travellers">
              <button
                onClick={() => {
                  setOpenTravellers(true);
                  setNewFlightid(flightData?.id);
                  console.log(flightData);
                  //setTripId(hotel.id)
                }}
              >
                Traveller Details
              </button>
            </div>
          </>
        )}
        {flightStatus?.note && (
          <p className="ml-[10pt] text-[10pt]">Note:{flightStatus?.note}</p>
        )}
      </div>
    </>
  );
}

export default Flight;
