import React, { useContext, useEffect, useState } from "react";
import "./AdminDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStarHalf,
  faStar,
  faIndianRupeeSign,
  faUpload,
  faUtensils,
  faCheckCircle,
  faBan,
  faDownload,
  faCircleInfo,
  faPencilAlt,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import MyContext from "../../Context";
import LoadingProg from "../../Loading/LoadingProg";
import Popup from "../../Popup";
import SideNav from "../SideNav/SideNav";
import Flight from "../../Flights/Flight/Flight";
import TravDetails from "../../Trips/TripDetails/TravellerDetails";
import Cab from "../../Cabs/Cab/Cab";
import HotelPriceCard from "../../Trips/TripDetails/HotelPriceCard";
import Bus from "../../Bus/Bus/Bus";
import { RiGlobalFill } from "react-icons/ri";
import { collection, getFirestore } from "@firebase/firestore";
import { format } from "date-fns";
import { getAuth } from "firebase/auth";
const AdminDetails = () => {
  const {
    actions,
    adminTripDetails,
    adminTripDataLoading,
    tripData,
    minimumServiceCharge,
    domesticFlight,
  } = useContext(MyContext);
  const params = useParams();
  const { id } = params;
  const db = getFirestore();
  console.log(db);
  //const tripData = submittedTrips.filter((trip) => trip.id === id)
  const [status, setStatus] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [file, setFile] = useState(null);
  const [note, setNote] = useState("");
  const [ticketCost, setTicketCost] = useState("");
  const [mounted, setMounted] = useState(true);
  const [openTravellers, setOpenTravellers] = useState(false);
  const [tripId, setTripId] = useState();
  const [loading, setLoading] = useState(false);
  const [hotelTotalPrice, setHotelTotalPrice] = useState(0);
  const [hotelFinalPrice, setHotelFinalPrice] = useState(0);
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [hotelData, setHotelData] = useState(null);
  const [travellerCount, setTravellerCount] = useState(null);
  const [hotelName, setHotelName] = useState(null);
  const [openAdminPrice, setOpenAdminPrice] = useState(false);
  const [openOtherBooking, setOpenOtherBooking] = useState(false);
  const [otherBookTravellersPopup, setOtherBookTravellersPopup] =
    useState(false);
  const [otherBookTravellers, setOtherBookTravellers] = useState();
  const [otherBookPrice, setOtherBookPrice] = useState({
    bookingTotal: 0,
    bookingService: 0,
    bookingGst: 0,
    totalBooking: 0,
  });
  var statuses = [
    "Submitted",
    // "Need clarification",
    // "Price Revision",
    "Booked",
    "Cancelled",
    // "Submitted,Payment Pending",
    // "Booked,Payment Pending",
  ];
  var tripstatuses = [
    { status: "Paid and Submitted", color: "#ffa500" },
    // { status: "Need clarification", color: "#FFC107" },
    // { status: "Price Revision", color: "#2196F3" },
    { status: "Booked", color: "#4CAF50" },
    { status: "Cancelled", color: "#FF0000" },
    // { status: "Submitted,Payment Pending", color: "#ffa500" },
    // { status: "Booked,Payment Pending", color: "#4CAF50" },
    { status: "Not Submitted", color: "#808080" },
  ];
  var reqStatuses = [
    { status: "Approved", color: "#008000" },
    { status: "Pending", color: "#ffa500" },
    { status: "Not Requested", color: "#808080" },
  ];

  var handleClick = async (hotelId, statuse, type) => {
    await actions.editTripStatus(
      adminTripDetails.data.userDetails.userid,
      adminTripDetails.data.tripId,
      adminTripDetails.id,
      statuse,
      hotelId,
      type
    );
    if (!file) {
      var bookingHotel = adminTripDetails?.hotels?.filter((hotel) => {
        return hotel.id === hotelId;
      });
      var bookingOther = adminTripDetails?.otherBookings?.filter((hotel) => {
        return hotel.id === hotelId;
      });
      if (status === "Booked" || status === "Booked,Payment Pending") {
        if (type === "add") {
          await actions.sendBookingStatusEmail({
            id: adminTripDetails?.data?.userDetails?.userid,
            name: adminTripDetails?.data?.userDetails?.firstName,
            email: adminTripDetails?.data?.userDetails?.email,
            fileUrl: null,
            tripName: adminTripDetails?.data?.tripName,
            typeName: hotelName,
            type: "Hotel",
            booking: bookingHotel,
            travellerDetails: adminTripDetails.data.travellerDetails,
          });
        } else if (type === "other") {
          await actions.sendBookingStatusEmail({
            id: adminTripDetails?.data?.userDetails?.userid,
            name: adminTripDetails?.data?.userDetails?.firstName,
            email: adminTripDetails?.data?.userDetails?.email,
            fileUrl: null,
            tripName: adminTripDetails?.data?.tripName,
            typeName: hotelName,
            type: "Other",
            booking: bookingOther,
          });
        }
      }
    }
  };
  var handleFileChange = (e) => {
    // if (e.target.files[0].size > 1024 * 1024) {
    //   setFile(null);
    //   alert("The file size should be less than 1 MB");
    // } else {
    setFile(e.target.files[0]);
    // }
  };
  var handleFileSubmit = async (type) => {
    var doc = await actions.addBookingDocuments(
      file,
      adminTripDetails.data.userDetails.userid,
      adminTripDetails.data.tripId,
      hotelId,
      adminTripDetails.id,
      type
    );
    var bookingHotel = adminTripDetails?.hotels?.filter((hotel) => {
      return hotel.id === hotelId;
    });
    var bookingOther = adminTripDetails?.otherBookings?.filter((hotel) => {
      return hotel.id === hotelId;
    });
    // if (status === "Booked" || status === "Booked,Payment Pending") {
    //   await actions.sendBookingStatusEmail({
    //     id: adminTripDetails?.data?.userDetails?.userid,
    //     name: adminTripDetails?.data?.userDetails?.firstName,
    //     email: adminTripDetails?.data?.userDetails?.email,
    //     fileUrl: doc,
    //     tripName: adminTripDetails?.data?.tripName,
    //     typeName: hotelName,
    //     type: type,
    //   });
    // }

    if (status === "Booked" || status === "Booked,Payment Pending") {
      if (type === "hotel") {
        await actions.sendBookingStatusEmail({
          id: adminTripDetails?.data?.userDetails?.userid,
          name: adminTripDetails?.data?.userDetails?.firstName,
          email: adminTripDetails?.data?.userDetails?.email,
          fileUrl: doc,
          tripName: adminTripDetails?.data?.tripName,
          typeName: hotelName,
          type: "Hotel",
          booking: bookingHotel,
          travellerDetails: adminTripDetails.data.travellerDetails,
        });
      } else if (type === "other") {
        await actions.sendBookingStatusEmail({
          id: adminTripDetails?.data?.userDetails?.userid,
          name: adminTripDetails?.data?.userDetails?.firstName,
          email: adminTripDetails?.data?.userDetails?.email,
          fileUrl: doc,
          tripName: adminTripDetails?.data?.tripName,
          typeName: hotelName,
          type: "Other",
          booking: bookingOther,
        });
      }
    }

    setFile(null);
    setOpenUpload(false);
  };
  var addTicketCost = async (type) => {
    await actions.addTicketCostAdmin(
      ticketCost,
      adminTripDetails.data.userDetails.userid,
      adminTripDetails.data.tripId,
      hotelId,
      adminTripDetails.id,
      type
    );
    setOpenUpload(false);
  };
  var addHotelNote = async (type) => {
    await actions.addNoteAdmin(
      note,
      adminTripDetails.data.userDetails.userid,
      adminTripDetails.data.tripId,
      hotelId,
      adminTripDetails.id,
      "hotel"
    );
    setOpenUpload(false);
  };
  var addOtherNote = async (type) => {
    await actions.addNoteAdmin(
      note,
      adminTripDetails.data.userDetails.userid,
      adminTripDetails.data.tripId,
      hotelId,
      adminTripDetails.id,
      "other"
    );
    setOpenUpload(false);
  };
  var getDate = (seconds) => {
    const timestampInSeconds = seconds;
    const date = new Date(timestampInSeconds * 1000);
    const dayOfWeek = date.getDate();
    const dayofyear = date.getFullYear();
    const month = date.toLocaleString("en-US", { month: "long" });
    var dateString = `${month.slice(0, 3)} ${dayOfWeek} ${dayofyear}`;
    return dateString;
  };

  useEffect(() => {
    if (mounted) {
      actions.getAdminTripById(id);
    }
    return () => {
      setMounted(false);
    };
  }, []);
  // console.log(tripData[0], adminTripDetails);
  // if (adminTripDataLoading) {
  //   return (
  //     <LoadingProg
  //       condition={adminTripDataLoading}
  //       loadingText="Getting Trip Data..."
  //       progEnd={adminTripDataLoading}
  //       progTime={7}
  //     />
  //     // <>Loading..</>
  //   );
  // }
  var getTime = (seconds) => {
    const timestampInSeconds = seconds;
    const date = new Date(timestampInSeconds * 1000);
    const dayOfWeek = date.toString().slice(4, 10);
    return dayOfWeek;
  };

  var startDate = adminTripDetails?.hotels
    ? getTime(
        adminTripDetails?.hotels[0]?.data?.hotelSearchQuery.checkInDate.seconds
      )
    : "";
  var endDate = adminTripDetails?.hotels
    ? getTime(
        adminTripDetails?.hotels[0]?.data?.hotelSearchQuery.checkOutDate.seconds
      )
    : "";
  var newDate = adminTripDetails?.flights
    ? new Date(
        adminTripDetails?.flights[0]?.data[0]?.flight.Segments[0][0].Origin.DepTime
      )
        .toString()
        .slice(4, 10)
    : "";
  var totalPrice =
    adminTripDetails?.flights?.reduce(
      (sum, obj) =>
        sum +
        (obj?.data[0]?.totalFare +
          obj?.data[0]?.finalFlightServiceCharge +
          obj?.data[0]?.gstInFinalserviceCharge),
      0
    ) +
    adminTripDetails?.hotels?.reduce(
      (sum, obj) => sum + obj?.data?.hotelTotalPrice,
      0
    ) +
    adminTripDetails?.cabs?.reduce(
      (sum, obj) => sum + obj?.data?.cabTotalPrice,
      0
    ) +
    adminTripDetails?.bus?.reduce(
      (sum, obj) => sum + obj?.data?.busTotalPrice,
      0
    ) +
    adminTripDetails?.otherBookings?.reduce(
      (sum, obj) => sum + obj?.data?.overallBookingPrice,
      0
    );
  var handleAdminDownload = async (downloadUrl) => {
    var downloadName = downloadUrl?.slice(164, downloadUrl.indexOf("?"));
    const response = await fetch(downloadUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = downloadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  var logOut = () => {
    actions.signOut();
  };

  var downloadDoc = async (downloadUrl) => {
    var downloadName = downloadUrl.slice(164, downloadUrl.indexOf("?"));
    const response = await fetch(downloadUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = downloadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  //console.log(adminTripDetails.data.travellerDetails["DdvRhDHsH6Qg7hANkHeM"]);
  const adminHotelTravAdult =
    adminTripDetails?.data?.travellerDetails[tripId]?.adults;
  const adminHotelTravChild =
    adminTripDetails?.data?.travellerDetails[tripId]?.children;
  console.log(adminTripDetails);
  return (
    <>
      {!adminTripDataLoading ? (
        <>
          <Popup
            condition={openUpload}
            close={() => {
              setOpenUpload(false);
              setHotelFinalPrice(0);
              setHotelTotalPrice(0);
              setSelectedRoom([]);
              setHotelId(null);
            }}
          >
            <div className="admin-upload">
              <div>
                <h3>Hotel Price:</h3>
                <HotelPriceCard
                  selectedRoom={selectedRoom}
                  hotelFinalPrice={hotelFinalPrice}
                  hotelTotalPrice={hotelTotalPrice}
                  hotelData={hotelData}
                />
              </div>
              <div className="admin-upload-popup">
                <div>
                  <span>Add ticket cost:</span>
                  <input
                    type="text"
                    placeholder="Enter Ticket Cost"
                    value={ticketCost}
                    onChange={(e) => setTicketCost(e.target.value)}
                  />
                </div>
                <div>
                  <span>Add a Note:</span>
                  <textarea
                    type="text"
                    placeholder="Add note"
                    value={note}
                    rows="4"
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
                <div className="admin-upload-file">
                  <div>
                    <span>Upload the file:</span>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </div>
                  {hotelData?.downloadURL ? (
                    <div className="flight-file">
                      <div onClick={handleAdminDownload}>
                        <FontAwesomeIcon
                          icon={faDownload}
                          onClick={() => downloadDoc(hotelData.downloadDoc)}
                        />
                        `{hotelData?.downloadURL?.slice(0, 34)}...`
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="adminPage-main-statusSave">
                  <div className="adminPage-main-status">
                    <div className="adminPage-main-status-text">Status</div>
                    <div className="adminPage-status-btn">
                      <select
                        onChange={async (e) => {
                          await setStatus(e.target.value);
                        }}
                      >
                        <option>
                          {
                            statuses.filter(
                              (status1) => status1 === hotelData?.status
                            )[0]
                          }
                        </option>
                        {statuses
                          .filter((status1) => status1 !== hotelData?.status)
                          .map((status1) => (
                            <option value={status1}>{status1}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <button className="spin">
                    <div className="spinner"></div>
                  </button>
                ) : (
                  <>
                    {hotelData?.status !== "Booked" &&
                      hotelData?.status !== "Cancelled" && (
                        <button
                          disabled={
                            hotelData?.status === "Booked" ||
                            hotelData?.status === "Cancelled"
                          }
                          onClick={async () => {
                            setLoading(true);
                            if (status === "Booked") {
                              actions.addInvoices(
                                adminTripDetails?.data?.userDetails?.userid,
                                adminTripDetails?.data?.tripId,
                                hotelId
                              );
                            }
                            if (status !== hotelData.status) {
                              await handleClick(hotelId, status, "add");
                            }
                            if (file) {
                              await handleFileSubmit("hotel");
                            }
                            if (ticketCost.length > 0) {
                              await addTicketCost("hotel");
                            }
                            if (note.length > 0) {
                              await addHotelNote();
                            }
                            await actions.getAdminTripById(id);
                            setLoading(false);
                          }}
                        >
                          Save Details
                        </button>
                      )}
                  </>
                )}
              </div>
            </div>
          </Popup>
          <Popup
            condition={openTravellers}
            close={() => setOpenTravellers(false)}
          >
            <div className="traveller-details-container">
              <div className="traveller-details-header">Traveller Details</div>
              {adminTripDetails?.data?.travellerDetails ? (
                <>
                  {adminHotelTravAdult &&
                    adminHotelTravAdult?.map((trav, i) => {
                      return (
                        <TravDetails type={"Adult"} index={i + 1} trav={trav} />
                      );
                    })}
                  {adminHotelTravChild &&
                    adminHotelTravChild?.map((trav, i) => {
                      return (
                        <TravDetails type={"Adult"} index={i + 1} trav={trav} />
                      );
                    })}
                </>
              ) : null}
            </div>
          </Popup>
          <Popup
            condition={openAdminPrice}
            close={() => {
              setOpenAdminPrice(false);
            }}
          >
            <HotelPriceCard
              selectedRoom={selectedRoom}
              hotelFinalPrice={hotelFinalPrice}
              hotelTotalPrice={hotelTotalPrice}
              hotelData={hotelData}
            />
          </Popup>
          <Popup
            condition={openOtherBooking}
            close={() => {
              setOpenOtherBooking(false);
            }}
          >
            <div>
              {/* <button
                onClick={() => handleClick(hotelId, "Booked", "other")}
                className="bg-black text-white rounded-lg py-1 px-2"
              >
                Book
              </button> */}
            </div>
            <div className="admin-upload">
              <div className="tripsPage-totalPrice-Desktop">
                <div className="tripsPage-totalPrice-section">
                  <div className="tripsPage-totalPrice">
                    <div className="tripsPage-totalPrice-title">
                      Other booking price:
                    </div>
                    <div className="tripsPage-totalPrice-price">
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        className="tripsPage-totalPrice-price-icon"
                      />
                      {`${otherBookPrice.bookingTotal.toLocaleString(
                        "en-IN"
                      )} `}
                    </div>
                  </div>
                </div>
                <div className="flightBook-fare-fareItem !flex">
                  <div className="flightBook-fare-fareItem-title">
                    Service Charges
                  </div>
                  <div className="flightBook-fare-fareItem-value">
                    {"+ "}
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="flightBook-fare-fareItem-value-icon"
                    />
                    {Math.ceil(otherBookPrice.bookingService)}
                  </div>
                </div>
                <div className="flightBook-fare-fareItem !flex !justify-between">
                  <div className="flightBook-fare-fareItem-title">GST</div>
                  <div className="flightBook-fare-fareItem-value">
                    {"+ "}
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="flightBook-fare-fareItem-value-icon"
                    />
                    {Math.ceil(otherBookPrice.bookingGst)}
                  </div>
                </div>
                <div className="tripsPage-totalPrice-sections">
                  <div className="tripsPage-totalPrice-title">Total price:</div>
                  <div className="tripsPage-totalPrice-price">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="tripsPage-totalPrice-price-icon"
                    />
                    {`${Math.ceil(otherBookPrice.totalBooking).toLocaleString(
                      "en-IN"
                    )} `}
                  </div>
                </div>
              </div>
              <div className="admin-upload-popup">
                <div>
                  <span>Add ticket cost:</span>
                  <input
                    type="text"
                    placeholder="Enter Ticket Cost"
                    value={ticketCost}
                    onChange={(e) => setTicketCost(e.target.value)}
                  />
                </div>
                <div>
                  <span>Add a Note:</span>
                  <textarea
                    type="text"
                    placeholder="Add note"
                    value={note}
                    rows="4"
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
                <div className="admin-upload-file">
                  <div>
                    <span>Upload the file:</span>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="adminPage-main-statusSave">
                  <div className="adminPage-main-status">
                    <div className="adminPage-main-status-text">Status</div>
                    <div className="adminPage-status-btn">
                      <select
                        onChange={async (e) => {
                          await setStatus(e.target.value);
                        }}
                      >
                        <option>
                          {
                            statuses.filter(
                              (status1) => status1 === hotelData?.status
                            )[0]
                          }
                        </option>
                        {statuses
                          .filter((status1) => status1 !== hotelData?.status)
                          .map((status1) => (
                            <option value={status1}>{status1}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <button className="spin">
                    <div className="spinner"></div>
                  </button>
                ) : (
                  <>
                    {hotelData?.status !== "Booked" &&
                      hotelData?.status !== "Cancelled" && (
                        <button
                          disabled={
                            hotelData?.status === "Booked" ||
                            hotelData?.status === "Cancelled"
                          }
                          onClick={async () => {
                            setLoading(true);
                            if (status === "Booked") {
                              actions.addInvoices(
                                adminTripDetails?.data?.userDetails?.userid,
                                adminTripDetails?.data?.tripId,
                                hotelId
                              );
                            }
                            if (status !== hotelData.status) {
                              await handleClick(hotelId, status, "other");
                            }
                            if (file) {
                              await handleFileSubmit("other");
                            }
                            if (ticketCost.length > 0) {
                              await addTicketCost("other");
                            }
                            if (note.length > 0) {
                              await addOtherNote();
                            }
                            await actions.getAdminTripById(id);
                            setLoading(false);
                            setOpenOtherBooking(false);
                          }}
                        >
                          Save Details
                        </button>
                      )}
                  </>
                )}
              </div>
            </div>
          </Popup>
          <Popup
            condition={otherBookTravellersPopup}
            close={() => {
              setOtherBookTravellersPopup(false);
            }}
          >
            {otherBookTravellers &&
              otherBookTravellers?.map((trav, i) => {
                return (
                  <div>
                    <h1 className="text-center font-semibold">Adult-{i + 1}</h1>
                    <p>
                      {trav.title} {trav.firstName} {trav.lastName}
                    </p>
                  </div>
                );
              })}
          </Popup>
          <div className="adminPage-block">
            <SideNav />
            <div className="adminPage-main-block">
              <div className="adminPage-main-header">
                <div className="adminPage-main-topHeader">
                  <div className="adminPage-main-logout">
                    <button onClick={logOut}>Logout</button>
                  </div>
                </div>
              </div>
              <div className="adminDetails-main-content">
                <div className="adminPage-main-section">
                  <div className="adminPage-main-body">
                    <div className="adminPage-main-body-name">Trip name</div>
                    <div className="adminPage-main-body-trip">
                      {adminTripDetails?.data?.tripName}
                    </div>
                    <div className="adminPage-main-body-date">
                      {/* {format(
                        adminTripDetails?.data?.date?.seconds,
                        "MMMM d, h:mm a"
                      )} */}
                    </div>
                  </div>
                  <div className="adminPage-main-totalPrice">
                    <span>TotalPrice:&nbsp;</span>
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="adminPage-main-totalPrice-icon"
                    />
                    {`${Math.ceil(totalPrice).toLocaleString("en-IN")}`}
                  </div>
                </div>
                <div className="adminPage-user-section">
                  <div className="adminPage-user-title">
                    User Account Details
                  </div>
                  <div className="adminPage-user-body">
                    <div className="adminPage-user-body-title">
                      <span>Name:</span>
                      {`${adminTripDetails?.data?.userDetails?.firstName} ${adminTripDetails?.data?.userDetails?.lastName}`}
                    </div>
                    <div className="adminPage-user-body-title">
                      <span>Email:</span>
                      {adminTripDetails?.data?.userDetails?.email}
                    </div>
                    <div className="adminPage-user-body-title">
                      <span>Mobile Number:</span>
                      {adminTripDetails?.data?.userDetails?.mobileNumber}
                    </div>
                    <div className="adminPage-user-body-title">
                      <span>Account Type:</span>
                      {adminTripDetails?.data?.userDetails?.accountType}
                    </div>
                    <div className="adminPage-user-body-title">
                      <span>Approval Type:</span>
                      {adminTripDetails?.data?.userDetails?.approvalType}
                    </div>
                  </div>
                </div>
                <div className="tripDetails-box">
                  <div className="tripDetails-hotel">
                    {adminTripDetails?.hotels?.length > 0 ? (
                      <div className="tripDetails-hotel-header">Hotels</div>
                    ) : null}
                    {adminTripDetails?.hotels
                      ?.sort((a, b) => {
                        var atime = new Date(
                          a?.data?.hotelSearchQuery?.checkInDate.seconds * 1000
                        );
                        var btime = new Date(
                          b?.data?.hotelSearchQuery?.checkInDate.seconds * 1000
                        );
                        return atime - btime;
                      })
                      ?.map((hotel, index) => {
                        const monthNames = [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ];
                        var rating = [];
                        var starRating =
                          hotel.data.hotelInfo.HotelInfoResult.HotelDetails
                            .StarRating;
                        var starRatingFull = Math.floor(starRating);
                        var downloadName = hotel?.downloadURL?.slice(
                          164,
                          hotel.downloadURL.indexOf("?")
                        );
                        const startdate = new Date(
                          hotel?.data?.hotelSearchQuery?.checkInDate.seconds *
                            1000
                        );
                        const formattedDate1 = `${
                          monthNames[startdate.getMonth()]
                        } ${startdate.getDate()}`;
                        var endDate = getDate(
                          hotel?.data?.hotelSearchQuery?.checkOutDate.seconds
                        );
                        console.log(startDate, formattedDate1, endDate);
                        var img = hotel?.data?.hotelInfo?.HotelInfoResult
                          ?.HotelDetails
                          ? hotel?.data?.hotelInfo?.HotelInfoResult
                              ?.HotelDetails.Images[0]
                          : "";
                        var color = tripstatuses.filter((status) => {
                          return status?.status === hotel?.status;
                        });
                        var hotelStatus =
                          adminTripDetails?.data?.hotels?.filter(
                            (f) => f.id === hotel.id
                          );
                        var adults =
                          hotel?.data?.hotelSearchQuery?.hotelRoomArr.reduce(
                            (acc, obj) => {
                              acc.adults += parseInt(obj.adults, 10);
                              acc.child += parseInt(obj.child, 10);
                              return acc;
                            },
                            { adults: 0, child: 0 }
                          );
                        var reqColor = reqStatuses.filter((status) => {
                          return status?.status === hotel?.requestStatus;
                        });
                        for (var i = 1; i <= Math.ceil(starRating); i++) {
                          if (i > starRatingFull) {
                            rating.push(
                              <FontAwesomeIcon
                                icon={faStarHalf}
                                className="hotel-card-rating-icon"
                              />
                            );
                          } else {
                            rating.push(
                              <FontAwesomeIcon
                                icon={faStar}
                                className="hotel-card-rating-icon"
                              />
                            );
                          }
                        }
                        return (
                          <div
                            className="hotel-card-total"
                            style={
                              hotel.status === "Booked"
                                ? { background: "honeydew" }
                                : null
                            }
                          >
                            <div className="hotel-card">
                              <div className="hotel-card-img">
                                <img src={img} alt="Hotel" />
                              </div>
                              <div className="hotel-card-details">
                                <div className="hotel-card-name">
                                  {
                                    hotel.data.hotelInfo.HotelInfoResult
                                      .HotelDetails.HotelName
                                  }
                                </div>

                                <span className="bg-[#94D2BD] float-right text-black font-semibold rounded-tl-[0.8rem] rounded-bl-[0.8rem] text-[13px] pl-[4px] py-[6px]">
                                  {formattedDate1}-{endDate}
                                  &nbsp;(
                                  {hotel.data.hotelSearchQuery.hotelNights}{" "}
                                  Nights)
                                </span>

                                <div className="hotel-card-details-row">
                                  <div className="hotel-card-rating">
                                    {rating.map((star) => {
                                      return star;
                                    })}
                                  </div>
                                  <div className="hotelInfo-details-passengers">
                                    Adults-{adults?.adults},&nbsp;Children-
                                    {adults?.child}
                                    <div>{hotel.data.hotelCode}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {hotel?.data?.selectedRoomType &&
                              hotel?.data?.selectedRoomType.map((room, f) => {
                                return (
                                  <div className="hotelInfo-roomDtls-room">
                                    <div className="hotelInfo-roomDtls-room-titleSection">
                                      <div className="hotelInfo-roomDtls-room-type">
                                        {room.RoomTypeName}
                                      </div>
                                      <div className="hotelInfo-roomDtls-room-price">
                                        <FontAwesomeIcon
                                          icon={faIndianRupeeSign}
                                          className="hotelInfo-roomDtls-room-price-icon"
                                        />

                                        {`${
                                          room.Price.OfferedPriceRoundedOff
                                            ? room.Price.OfferedPriceRoundedOff.toLocaleString(
                                                "en-IN"
                                              )
                                            : room?.Price.PublishedPriceRoundedOff.toLocaleString(
                                                "en-IN"
                                              )
                                        } `}
                                      </div>
                                    </div>
                                    <div className="hotelInfo-roomDtls-room-otherSection">
                                      <div className="hotelInfo-roomDtls-room-meals">
                                        <FontAwesomeIcon
                                          icon={faUtensils}
                                          className="hotelInfo-roomDtls-room-meals-icon"
                                        />
                                        {room.Inclusion &&
                                        room.Inclusion.length > 0
                                          ? actions.checkForTboMeals(
                                              room.Inclusion
                                            )
                                          : "No meals"}
                                      </div>
                                      <div className="hotelInfo-roomDtls-room-cancel">
                                        {room.LastCancellationDate &&
                                        actions.validCancelDate(
                                          room.LastCancellationDate
                                        ) ? (
                                          <>
                                            <FontAwesomeIcon
                                              icon={faCheckCircle}
                                              className="hotelInfo-roomDtls-room-cancel-icon"
                                            />
                                            {`Free cancellation upto ${new Date(
                                              room.LastCancellationDate
                                            )
                                              .toString()
                                              .slice(4, 10)}`}
                                          </>
                                        ) : (
                                          <>
                                            <FontAwesomeIcon
                                              icon={faBan}
                                              className="hotelInfo-roomDtls-room-cancel-icon"
                                            />
                                            {"Non-refundable"}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="hotelInfo-roomDtls-room-inclusions">
                                      {room.Inclusion.length > 0
                                        ? room.Inclusion.map((inclusion) => {
                                            return <span>{inclusion}</span>;
                                          })
                                        : null}
                                    </div>
                                  </div>
                                );
                              })}
                            <div className="seperate"></div>
                            <div className="hotel-card-status">
                              <div className="request-status">
                                Approval Status:
                                <span
                                  style={{
                                    background: reqColor[0]
                                      ? reqColor[0].color
                                      : "#808080",
                                  }}
                                >
                                  {hotel?.requestStatus}
                                </span>
                              </div>
                              <div className="hotelType">
                                <div className="hotelStatus">
                                  Booking Status:
                                  <span
                                    style={{
                                      background: color[0]
                                        ? color[0].color
                                        : "#808080",
                                    }}
                                  >
                                    {hotel.status}
                                  </span>
                                </div>
                              </div>

                              <div className="flightResults-list-flightCard-price-trips">
                                Total Price:{" "}
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="flightResults-list-flightCard-price-icon"
                                />
                                {`${Math.ceil(
                                  hotel.data.hotelTotalPrice
                                ).toLocaleString("en-IN")}`}
                                &nbsp;
                                <FontAwesomeIcon
                                  icon={faCircleInfo}
                                  className="info-icon"
                                  onClick={() => {
                                    setHotelFinalPrice(
                                      hotel.data.hotelFinalPrice
                                    );
                                    setHotelTotalPrice(
                                      hotel.data.hotelTotalPrice
                                    );
                                    setSelectedRoom(
                                      hotel.data.selectedRoomType
                                    );
                                    setHotelData(hotel);
                                    setOpenAdminPrice(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="seperate"></div>
                            <div className="hotel-card-admin-details-upload">
                              {hotel.downloadURL ? (
                                <div className="hotel-file">
                                  <div
                                    onClick={() => {
                                      handleAdminDownload(hotel.downloadURL);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faDownload} />`
                                    {downloadName.slice(0, 14)}...`
                                  </div>
                                </div>
                              ) : null}
                              {hotel.ticketCost ? (
                                <div className="hotel-ticket-cost">
                                  Ticket Cost:{" "}
                                  <span>
                                    <FontAwesomeIcon
                                      icon={faIndianRupeeSign}
                                      className="hotelInfo-roomDtls-room-price-icon"
                                    />{" "}
                                    {hotel.ticketCost}
                                  </span>
                                </div>
                              ) : null}
                              {hotel.note ? (
                                <div className="flight-ticket-cost">
                                  Note: {hotel.note}
                                </div>
                              ) : null}
                              {hotel?.note ||
                              hotel?.ticketCost ||
                              hotel?.downloadURL ? (
                                <FontAwesomeIcon
                                  icon={faPencilAlt}
                                  className="upload-icon"
                                  onClick={() => {
                                    setOpenUpload(true);
                                    setStatus(hotel.status);
                                    setNote(hotel.note ? hotel.note : "");
                                    setTicketCost(
                                      hotel.ticketCost ? hotel.ticketCost : ""
                                    );
                                    setHotelId(hotel.id);
                                    setHotelData(hotel);
                                  }}
                                />
                              ) : null}
                            </div>
                            {hotel?.note ||
                            hotel?.ticketCost ||
                            hotel?.downloadURL ? (
                              <div className="seperate"></div>
                            ) : null}

                            <div className="hotel-card-admin-details">
                              <div
                                className="hotel-card-upload"
                                onClick={() => {
                                  console.log(hotelStatus);
                                  setOpenUpload(true);
                                  setStatus(hotel.status);
                                  setNote(hotel.note ? hotel.note : "");
                                  setTicketCost(
                                    hotel.ticketCost ? hotel.ticketCost : ""
                                  );
                                  setHotelId(hotel.id);
                                  setHotelFinalPrice(
                                    hotel.data.hotelFinalPrice
                                  );
                                  setHotelTotalPrice(
                                    hotel.data.hotelTotalPrice
                                  );
                                  setSelectedRoom(hotel.data.selectedRoomType);
                                  setHotelData(hotel);
                                  setHotelName(
                                    hotel.data.hotelInfo.HotelInfoResult
                                      .HotelDetails.HotelName
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faUpload} />
                                &nbsp; Booking
                              </div>
                              <div className="hotel-travellers">
                                <button
                                  onClick={() => {
                                    setOpenTravellers(!openTravellers);
                                    setTripId(hotel.id);
                                    setTravellerCount(adults);
                                  }}
                                >
                                  Traveller Details
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {adminTripDetails?.flights?.length > 0 ? (
                      <div className="tripDetails-hotel-header">Flights</div>
                    ) : null}
                    {adminTripDetails?.flights ? (
                      <>
                        {adminTripDetails?.flights
                          ?.sort((a, b) => {
                            var aflightArr = [a.data[0].flight].map(
                              (flight, f) => {
                                return {
                                  ...actions.modifyFlightObject(flight),
                                };
                              }
                            );
                            var bflightArr = [b.data[0].flight].map(
                              (flight, f) => {
                                return {
                                  ...actions.modifyFlightObject(flight),
                                };
                              }
                            );
                            return (
                              aflightArr[0]?.segments[0]?.depTimeDate -
                              bflightArr[0]?.segments[0]?.depTimeDate
                            );
                          })
                          ?.map((flight) => {
                            var reqColor = reqStatuses.filter((status) => {
                              return status?.status === flight?.requestStatus;
                            });
                            console.log(flight);
                            const charge =
                              flight?.data[0]?.totalFare === 0
                                ? 0
                                : Math.ceil(
                                    (flight?.data[0]?.totalFare *
                                      domesticFlight) /
                                      100
                                  ) > minimumServiceCharge
                                ? Math.ceil(
                                    (flight?.data[0]?.totalFare *
                                      domesticFlight) /
                                      100
                                  )
                                : minimumServiceCharge;

                            const gst =
                              flight?.data[0]?.totalFare === 0
                                ? 0
                                : Math.ceil(charge * 0.18);
                            console.log(charge, gst);
                            return (
                              <Flight
                                flightGrp={[flight?.data[0]?.flight]}
                                tripsPage={false}
                                bookingPage={true}
                                adminPage={true}
                                flightBooking={flight.data[0]}
                                adminBooking={adminTripDetails}
                                flightData={flight}
                                travellerDetails={
                                  adminTripDetails?.data?.travellerDetails
                                    ? adminTripDetails?.data?.travellerDetails[
                                        flight.id
                                      ]
                                    : []
                                }
                                reqColor={reqColor}
                                adminTripid={id}
                                flightStatus={flight}
                                charge={charge}
                                gst={gst}
                              />
                            );
                          })}
                      </>
                    ) : null}
                    {adminTripDetails?.cabs?.length > 0 ? (
                      <div className="tripDetails-hotel-header">Cabs</div>
                    ) : null}
                    {adminTripDetails?.cabs ? (
                      <>
                        {adminTripDetails?.cabs
                          ?.sort((a, b) => {
                            var adate = a.data.cabStartDate;
                            var bdate = b.data.cabStartDate;
                            return adate - bdate;
                          })
                          ?.map((flight) => {
                            var reqColor = reqStatuses.filter((status) => {
                              return status?.status === flight?.requestStatus;
                            });

                            var cabReq = adminTripDetails?.data?.cabs?.filter(
                              (hotelMain) => {
                                return hotelMain.id === flight.id;
                              }
                            );
                            console.log(flight);

                            return (
                              <>
                                {flight && (
                                  <Cab
                                    tripsPage={false}
                                    adminPage={true}
                                    bookingPage={true}
                                    adminBooking={adminTripDetails}
                                    cabData={flight}
                                    startDate={flight.data.cabStartDate}
                                    endDate={flight.data.cabEndDate}
                                    travellerDetails={
                                      adminTripDetails?.data?.travellerDetails
                                        ? adminTripDetails?.data
                                            ?.travellerDetails[flight.id]
                                        : []
                                    }
                                    reqColor={reqColor}
                                    adminTripid={id}
                                    cab={flight.data.cab}
                                    cabTotal={flight.data}
                                    cabTotalPrice={flight.data.cabTotalPrice}
                                    tripsCabType={flight.data.cabType}
                                  />
                                )}
                              </>
                            );
                          })}
                      </>
                    ) : null}
                    {adminTripDetails?.bus?.length > 0 ? (
                      <div className="tripDetails-hotel-header">Bus</div>
                    ) : null}
                    {adminTripDetails?.bus ? (
                      <>
                        {adminTripDetails?.bus
                          ?.sort((a, b) => {
                            var adate = a?.data?.bus?.DepartureTime;
                            var bdate = b?.data?.bus?.ArrivalTime;
                            return adate - bdate;
                          })
                          ?.map((flight) => {
                            return (
                              <>
                                <Bus
                                  bookingPage={true}
                                  bus={flight?.data?.bus}
                                  tripsPage={false}
                                  adminPage={true}
                                  bookingBus={flight?.data}
                                  busData={flight}
                                  adminTripid={id}
                                  adminBooking={adminTripDetails}
                                  travellerDetails={
                                    adminTripDetails?.data?.travellerDetails
                                      ? adminTripDetails?.data
                                          ?.travellerDetails[flight.id]
                                      : []
                                  }
                                />
                              </>
                            );
                          })}
                      </>
                    ) : null}
                    {adminTripDetails?.otherBookings?.length > 0 ? (
                      <div className="tripDetails-hotel-header">
                        Other Bookings
                      </div>
                    ) : null}
                    {adminTripDetails?.otherBookings?.map((other) => {
                      console.log(adminTripDetails);
                      const otherM =
                        adminTripDetails?.data?.otherBookings?.filter(
                          (otherMain) => {
                            return otherMain.id === other.id;
                          }
                        );
                      console.log(otherM[0]);
                      var color = tripstatuses?.filter((status) => {
                        return status?.status === otherM[0]?.status;
                      });
                      var reqColor = reqStatuses.filter((status) => {
                        return status?.status === otherM[0]?.requestStatus;
                      });

                      const backgroundColor =
                        otherM[0]?.status === "Booked" ? "honeydew" : "inherit";
                      return (
                        <div
                          style={{
                            boxShadow:
                              "0.04rem 0.06rem 0.4rem rgba(0, 0, 0, 0.171)",
                            backgroundColor: backgroundColor,
                          }}
                          className="w-[90%] rounded-[0.8rem] mt-[10pt] pt-[10pt] pl-[10pt] pb-[3pt]"
                        >
                          <div>
                            <div className="flex items-center">
                              <RiGlobalFill size={50} />
                              <div className="w-[100%]">
                                <div className="flex items-center justify-between">
                                  <p className="font-bold text-[12pt] mr-[5px]">
                                    {other?.data?.bookingType}
                                  </p>
                                  <p className="text-[10pt] bg-[#94d2bd] p-[5pt] rounded-tl-[0.8rem] rounded-bl-[0.8rem]">
                                    {other?.data?.bookingDate}
                                  </p>
                                </div>
                                <div className="flex items-center justify-between pt-1">
                                  <p>{other?.data?.bookingDetails}</p>
                                  <p className="text-[#BB3E03] font-bold">
                                    <FontAwesomeIcon
                                      icon={faIndianRupeeSign}
                                      className="tripsPage-totalPrice-price-icon"
                                    />
                                    {other?.data?.bookingCost}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="border-[1px] border-dotted border-[#001219] mt-1"></div>
                            <div className="flex justify-around items-center">
                              <p className="text-[#BB3E03] font-bold">
                                Total Price:
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className="tripsPage-totalPrice-price-icon"
                                />{" "}
                                {other?.data?.overallBookingPrice}
                              </p>
                            </div>
                            <div className="border-[1px] border-dotted border-[#001219] mb-1"></div>
                            <div className="flex items-center justify-between">
                              <p className="text-[10pt]">
                                Approval Status:
                                <span
                                  style={{
                                    background: reqColor[0]
                                      ? reqColor[0]?.color
                                      : "#808080",
                                  }}
                                  className="py-[3pt] px-[5pt] rounded-[0.8rem] text-white text-[8pt] font-bold"
                                >
                                  {otherM[0].requestStatus}
                                </span>
                              </p>
                              <p className="text-[10pt]">
                                Booking Status:
                                <span
                                  style={{
                                    background: color[0]
                                      ? color[0]?.color
                                      : "#808080",
                                  }}
                                  className="py-[3pt] px-[5pt] rounded-[0.8rem] text-white text-[8pt] font-bold"
                                >
                                  {otherM[0].status}
                                </span>
                              </p>
                            </div>
                          </div>
                          {/* <button
                            onClick={() => {
                              setOpenOtherBooking(true);
                              setHotelId(other.id);
                            }}
                          >
                            Booking
                          </button> */}
                          <div className="hotel-card-admin-details flex items-center justify-around mt-2 mb-2 cursor-pointer">
                            <div
                              className="hotel-card-upload"
                              onClick={() => {
                                setOpenOtherBooking(true);
                                setHotelId(other.id);
                                setStatus(other.status);

                                setNote(other.note ? other.note : "");
                                setTicketCost(
                                  other.ticketCost ? other.ticketCost : ""
                                );
                                setHotelData(otherM[0]);
                                setOtherBookPrice((prevState) => ({
                                  ...prevState,
                                  bookingTotal: other?.data?.bookingCost,
                                  bookingService: other?.data?.bookingService,
                                  bookingGst: other?.data?.bookingGst,
                                  totalBooking:
                                    other?.data?.overallBookingPrice,
                                }));
                              }}
                            >
                              <FontAwesomeIcon icon={faUpload} />
                              &nbsp; Booking
                            </div>
                            <div className="hotel-travellers">
                              <button
                                onClick={() => {
                                  setOtherBookTravellersPopup(true);
                                  setOtherBookTravellers(
                                    other?.data?.bookingTravellers
                                  );
                                }}
                              >
                                Traveller Details
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* {adminTripDetails?.data?.} */}
                    {adminTripDetails?.bookingComments && (
                      <h1 className="font-bold text-[20px] mt-4">Comments</h1>
                    )}
                    {adminTripDetails?.bookingComments?.map(
                      (comment, index) => {
                        return (
                          <div className="shadow-md w-[90%] mt-2 py-4 px-2">
                            <p className="font-semibold">Booking {index + 1}</p>
                            <p>
                              <span className="font-semibold">Comments: </span>
                              {comment?.adminComment}
                            </p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Loading</p>
      )}
    </>
  );
};

export default AdminDetails;
