import React, { useState, useContext, useEffect } from "react";
import MyContext from "../../Context";
import "./LoginPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";
import Popup from "../../Popup";
const LoginPage = () => {
  const auth = getAuth();
  const { actions, userAccountDetails, loginError, userLoginStatus } =
    useContext(MyContext);
  console.log(loginError);
  var { state } = useLocation();
  var navigate = useNavigate();
  var [showToast, setShowToast] = useState(false);
  var [logging, setLogging] = useState(false);
  const [forgotPopup, setForgotPopup] = useState(false);
  var [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const setData = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
    validateInput(e.target.name, e.target.value);
  };
  const validateInput = (name, value) => {
    if (name === "email") {
      setErrors({
        ...errors,
        email: /^\S+@\S+\.\S+$/.test(value) ? "" : "Invalid email address",
      });
    } else if (name === "password") {
      setErrors({
        ...errors,
        password: value.trim() === "" ? "Password is required" : "",
      });
    }
  };

  var isValid = () => {
    return Object.values(errors).every((error) => error === "");
  };

  const submitBtn = async () => {
    if (userData.email === "") {
      return false;
    }
    if (userData.password === "") {
      return false;
    }
    if (isValid()) {
      setLogging(true);
      var role = await actions.logIn(userData);
      if (!role) {
        console.log("Error");
        setLogging(false);
        toast.error("Invalid Credentials", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        if (role === "admin") {
          navigate("/admin");
          await actions.getAllUserTrips();
          await actions.getSubmittedTrips();
        } else {
          navigate("/home/flights");
        }
      }
    } else {
      alert("All feilds are required");
    }
  };
  const handleFacebookClick = () => {
    console.log("Facebook icon is clicked");
    //actions.signInWithFacebook();
  };
  const handleGoogleClick = () => {
    console.log("Google icon is clicked");
    //actions.signInWithGoogle();
  };
  const handleSubmit = async (event) => {
    setForgotPopup(true);
    // event.preventDefault();
    // try {
    //   await sendPasswordResetEmail(auth, auth.currentUser.email);
    //   // setMessage("Password reset email sent. Please check your inbox.");
    // } catch (error) {
    //   // setError(
    //   //   "Failed to send password reset email. Please check the email address."
    //   // );
    // }
  };
  useEffect(() => {
    if (state && state.shouldShowToast) {
      if (!userAccountDetails) {
        setShowToast(true);
        const timeoutId = setTimeout(() => {
          setShowToast(false);
        }, 5000);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [state, userAccountDetails]);
  return (
    <>
      <Popup condition={forgotPopup} close={() => setForgotPopup(false)}>
        <h1>Please send an email to support@tripbizz.com</h1>
        <p>OR</p>
        <h1>Contact +91 9949269044</h1>
      </Popup>
      <div className="loginPage-block">
        <ToastContainer></ToastContainer>
        <div className="loginPage-container">
          <h1 className="loginPage-header">Login</h1>
          <div className="loginPage-body">
            <div className="loginPage-type">
              Email
              <div className="loginPage-type-input">
                <input
                  className="focus:outline-none"
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                  id="email"
                  value={userData.email}
                  onChange={(e) => setData(e)}
                />
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </div>
              <div className="error-message">{errors.email}</div>
            </div>
            <div className="loginPage-type">
              Password
              <div className="loginPage-type-input">
                <input
                  className="focus:outline-none"
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={userData.password}
                  onChange={(e) => setData(e)}
                  id="password"
                />
                <span>
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </div>
              <div className="error-message">{errors.password}</div>
            </div>
            <div
              className="loginPage-forgotPassword !cursor-pointer"
              onClick={handleSubmit}
            >
              Forgot password?
            </div>
            <div className={`loginPage-submit ${logging ? "loading" : ""}`}>
              {logging ? (
                <button className="spin">
                  <div className="spinner"></div>
                </button>
              ) : (
                <button
                  onClick={submitBtn}
                  disabled={!isValid()}
                  className="login"
                  id="#login-button"
                  type="submit"
                >
                  Login
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showToast && (
        <div className="alert-text">
          <span>Please login to continue to home page</span>
          <div className="alert-bar"></div>
        </div>
      )}
    </>
  );
};

export default LoginPage;
