import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FlightSearch from "./Components/Flights/FlightSearch/FlightSearch";
import MyProvider from "./Components/MyProvider";
import MyContext from "./Components/Context";
import FlightBooking from "./Components/Flights/FlightBooking/FlightBooking";
import LoginPage from "./Components/Authentication/LoginPage/LoginPage";
import SignUpPage from "./Components/Authentication/SignUpPage/SignUpPage";
import MyTrips from "./Components/Trips/MyTrips/MyTrips";
import TripDetails from "./Components/Trips/TripDetails/TripDetails";
import AdminPage from "./Components/Admin/AdminPage/AdminPage";
import AdminDetails from "./Components/Admin/AdminDetails/AdminDetails";
import AdminRoute from "./Components/ProtectedRoute/AdminRoute";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import FlightSetting from "./Components/Admin/FlightSettings/FlightSetting";
import HotelSetting from "./Components/Admin/HotelSettings/HotelSetting";
import Users from "./Components/Admin/Users/Users";
import ChangePassword from "./Components/User/ChangePassword/ChangePassword";
import Home from "./Components/Home/Home";
import Privacy from "./Components/Home/Privacy";
import TermsAndConditions from "./Components/Home/TermsAndConditions";
import CancellationPolicy from "./Components/Home/CancellationPolicy";
import Profile from "./Components/User/Profile/Profile";
import CommissionSetting from "./Components/Admin/CommissionSetting/CommissionSetting";
import Role from "./Components/User/Role/Role";
import Wallet from "./Components/Wallet/Wallet";
import UserProfile from "./Components/Admin/UserProfile/UserProfile";
import UserTrips from "./Components/Admin/UserTrips/UserTrips";
import Report from "./Components/Admin/Report/Report";
import AllRequests from "./Components/User/Role/AllRequests";
import AllBookings from "./Components/Admin/AdminPage/AllBookings";
import Downloads from "./Components/Downloads/Downloads";

function App() {
  return (
    <MyProvider>
      <Router>
        <MyContext.Consumer>
          {({ actions }) => {
            return (
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/cancellation" element={<CancellationPolicy />} />
                <Route path="/downloads" element={<Downloads />} />
                <Route
                  path="/home"
                  element={<ProtectedRoute element={<FlightSearch />} />}
                />
                <Route
                  path="/home/flights"
                  element={
                    <ProtectedRoute element={<FlightSearch tab="flights" />} />
                  }
                />
                <Route
                  path="/home/hotels"
                  element={
                    <ProtectedRoute element={<FlightSearch tab="hotels" />} />
                  }
                />
                <Route
                  path="/home/cabs"
                  element={
                    <ProtectedRoute element={<FlightSearch tab="cabs" />} />
                  }
                />
                <Route
                  path="/home/bus"
                  element={
                    <ProtectedRoute element={<FlightSearch tab="bus" />} />
                  }
                />
                <Route
                  path="/login"
                  element={
                    <ProtectedRoute element={<FlightSearch tab="flights" />} />
                  }
                />
                {/* <Route
                  path="/signup"
                  element={
                    <ProtectedRoute
                      element={<FlightSearch tab="flights" />}
                      signUp={true}
                    />
                  }
                /> */}
                <Route
                  path="/trips"
                  element={<ProtectedRoute element={<MyTrips />} />}
                />
                <Route
                  path="/trips/:id"
                  element={<ProtectedRoute element={<TripDetails />} />}
                />
                <Route
                  path="/changepassword"
                  element={<ProtectedRoute element={<ChangePassword />} />}
                />
                <Route
                  path="/profile"
                  element={<ProtectedRoute element={<Profile />} />}
                />
                <Route
                  path="/roles"
                  element={<ProtectedRoute element={<Role />} />}
                />
                <Route
                  path="/allrequests"
                  element={<ProtectedRoute element={<AllRequests />} />}
                />
                <Route
                  path="/wallet"
                  element={<ProtectedRoute element={<Wallet />} />}
                />
                <Route
                  path="/admin"
                  element={<AdminRoute element={<AdminPage />} />}
                />
                <Route
                  path="/admin/:id"
                  element={<AdminRoute element={<AdminDetails />} />}
                />
                <Route
                  path="/flightsetting"
                  element={<AdminRoute element={<FlightSetting />} />}
                />
                <Route
                  path="/hotelsetting"
                  element={<AdminRoute element={<HotelSetting />} />}
                />
                <Route
                  path="/commissionsetting"
                  element={<AdminRoute element={<CommissionSetting />} />}
                />
                <Route
                  path="/report"
                  element={<AdminRoute element={<Report />} />}
                />

                <Route
                  path="/users"
                  element={<AdminRoute element={<Users />} />}
                />
                <Route
                  path="/allbookings"
                  element={<AdminRoute element={<AllBookings />} />}
                />
                <Route
                  path="/users/:id"
                  element={<AdminRoute element={<UserProfile />} />}
                />
                <Route
                  path="/users/:userId/trips/:tripId"
                  element={<AdminRoute element={<UserTrips />} />}
                />
              </Routes>
            );
          }}
        </MyContext.Consumer>
      </Router>
    </MyProvider>
  );
}

export default App;
